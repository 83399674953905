//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import { Link } from 'gatsby';
import { Router, Redirect } from '@reach/router';
import { Helmet } from 'react-helmet';
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Logo as LogoIcon, Footer } from '@cmp/common';
import { Sidebar } from '@cmp/authenticated';
import Post from '@pages/authenticated/post';
//------------------------------------------------------------------------------
// HOC -------------------------------------------------------------------------
import { withAuthUser, withModal } from '@cmp/common/hoc';
//------------------------------------------------------------------------------
// Constants -------------------------------------------------------------------
import { PhotoDisclaimer } from '@helpers/constants/legal';
import { Routes } from '@helpers/constants/routes';
//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------
import { signOut } from '@api/endpoints/post';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { deauth } from '@helpers/auth';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Authenticated extends React.Component {
  signOutClicked() {
    signOut().finally(() => deauth());
  }

  headerComponent() {
    return (
      <header className={styles.content__header}>
        <Link to="/">
          <LogoIcon className={styles.header__logo} />
        </Link>
        <p className={styles.header__disclaimer}>{PhotoDisclaimer}</p>
      </header>
    );
  }

  render() {
    const { __authUser: { user } = {}, from } = this.props;
    if (!user) return '';

    const routes = Routes(user);
    const filteredRoutes = routes.filter((x) => x.component);

    return (
      <>
        <Router primary={false}>
          <Post path="/post/:id" />
        </Router>
        <div className={styles.authenticated}>
          <Helmet
            defaultTitle="Porsche Exclusive Gallery"
            titleTemplate="%s | Porsche Exclusive Gallery"
          />

          <Sidebar
            className={styles.authenticated__sidebar}
            signOutClicked={() => this.signOutClicked()}
          />
          <div className={styles.authenticated__content} tabIndex="-1">
            {this.headerComponent()}
            <div className={styles.authenticated__route}>
              <Router location={from}>
                {filteredRoutes.map(({ component: Component, path }) => (
                  <Component path={path} key={path} location={from} />
                ))}
                <Redirect from="*" to="/" noThrow />
              </Router>
            </div>
            <Footer />
          </div>
        </div>
      </>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withAuthUser(withModal(Authenticated));
