//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Modal, Button, ButtonKind } from '@cmp/common';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class ModalGeneric extends React.Component {
  render() {
    const {
      className,
      title,
      description,
      children,
      actions = [
        {
          title: 'Close',
          onClick: this.props.onClose,
          kind: ButtonKind.Interrupt,
        },
      ],
      ...rest
    } = this.props;

    const componentClasses = classNames(styles.modal_generic, {
      [className]: className,
    });

    return (
      <Modal.Base className={componentClasses} {...rest}>
        {title && <h1 className={styles.modal_generic__title}>{title}</h1>}
        {description && (
          <p className={styles.modal_generic__description}>{description}</p>
        )}
        {children}
        {actions && (
          <div className={styles.modal_generic__actions}>
            {actions.map(({ title, onClick, ...rest }) => (
              <Button onClick={onClick} {...rest} key={title}>
                {title}
              </Button>
            ))}
          </div>
        )}
      </Modal.Base>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default ModalGeneric;
