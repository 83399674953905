//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// Endpoints -------------------------------------------------------------------
import { refresh } from '@api/endpoints/post';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Button } from '@cmp/common';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Refresh extends React.Component {
  onRefresh() {
    refresh().then(console.log).catch(console.error);
  }

  render() {
    const { className } = this.props;

    const componentClasses = classNames(styles.refresh, {
      [className]: className,
    });

    return (
      <div className={componentClasses}>
        <Button onClick={() => this.onRefresh()}>Refresh</Button>
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Refresh;
