//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { FaTimes as CloseIcon } from 'react-icons/fa';
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class SearchBar extends React.Component {
  render() {
    const {
      className,
      onSearch,
      onClear,
      value,
      placeholder,
      onChange,
    } = this.props;

    const componentClasses = classNames(styles.search_bar, {
      [className]: className,
    });

    const resetComponentClasses = classNames(styles.input__reset, {
      [styles[`input__reset--hidden`]]: !value || value.length <= 0,
    });

    return (
      <form
        className={componentClasses}
        onSubmit={(e) => {
          e.preventDefault();
          onSearch();
        }}
      >
        <div className={styles.search_bar__input}>
          <input
            type="text"
            className={styles.input_el}
            value={value}
            onChange={({ currentTarget: { value } }) => onChange(value)}
            placeholder={placeholder}
          />
          <CloseIcon
            className={resetComponentClasses}
            onClick={() => onClear()}
          />
        </div>
        <button type="submit" className={styles.search_bar__button}>
          Search
        </button>
      </form>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default SearchBar;
