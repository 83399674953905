//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { FaPowerOff as SignOutIcon } from 'react-icons/fa';
import { Link } from 'gatsby';
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// Helpers & Constants ---------------------------------------------------------
import { Routes } from '@helpers/constants/routes';
//------------------------------------------------------------------------------
// Assets ----------------------------------------------------------------------
import { UserPlaceholderImage } from '@img/common';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Image } from '@cmp/common';
import { withAuthUser } from '@cmp/common/hoc';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Sidebar extends React.Component {
  itemComponent({ link, customUrl, title, exact, icon: IconComponent }) {
    const children = (
      <>
        <IconComponent />
        {/* To-do (P3): Add left chevron to the tooltip */}
        {/*<span className={styles.item__tooltip}>{title}</span>*/}
      </>
    );

    if (customUrl) {
      return (
        <a href={customUrl} key={title} className={styles.sidebar__item}>
          {children}
        </a>
      );
    }

    return (
      <Link
        to={link || '/'}
        partiallyActive={!exact}
        key={title}
        className={styles.sidebar__item}
        activeClassName={styles[`sidebar__item--active`]}
      >
        {children}
      </Link>
    );
  }

  itemsComponent() {
    const {
      __authUser: { user },
    } = this.props;

    const routes = Routes(user);
    const filteredRoutes = routes.filter(
      (x) => x.title && (x.link || x.customUrl)
    );

    return (
      <div className={styles.sidebar__items}>
        {/* ---------------------------------------------------------------- */}
        {/* Profile -------------------------------------------------------- */}
        <Link
          to="/profile"
          className={styles.sidebar__item}
          activeClassName={styles[`sidebar__item--highlighted`]}
        >
          <Image
            src={user && user.profileImageUrl}
            customPlaceholder={UserPlaceholderImage}
            className={styles.items__profile_picture}
            alt="Profile"
          />
        </Link>
        {/* ---------------------------------------------------------------- */}
        {/* Items by role -------------------------------------------------- */}
        {filteredRoutes.map((item) => this.itemComponent(item))}
      </div>
    );
  }

  render() {
    const { className, signOutClicked } = this.props;

    const componentClasses = classNames(styles.sidebar, {
      [className]: className,
    });

    return (
      <div className={componentClasses}>
        {/* ---------------------------------------------------------------- */}
        {/* Items ---------------------------------------------------------- */}
        {this.itemsComponent()}
        {/* ---------------------------------------------------------------- */}
        {/* Sign out ------------------------------------------------------- */}
        {/* Wrapped in a `button` so it's more accessible, and easier to style -
            the react-icons svg -------------------------------------------- */}
        <button className={styles.sidebar__item} onClick={signOutClicked}>
          <SignOutIcon />
        </button>
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withAuthUser(Sidebar);
