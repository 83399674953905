//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import {
  FaUserAlt as UserIcon,
  FaUserPlus as UserPlusIcon,
  FaBuilding as OrganizationIcon,
} from 'react-icons/fa';
import { isEmail, isEmpty } from 'validator';
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Input, Button, Feedback, FeedbackKind } from '@cmp/common';
//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------
import { signUp } from '@api/endpoints/post';
//------------------------------------------------------------------------------
// Helpers, Classes & Constants ------------------------------------------------
import FormValidator from '@/classes/formValidator';
import { StatusMessages } from '@helpers/constants/api';
import { validationFormatter } from '@helpers/formatter';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class SignUp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      firstName: '',
      lastName: '',
      organization: '',
      loading: false,
      feedback: null,
    };
  }

  componentDidMount() {
    const items = [
      {
        key: 'email',
        defaultValue: '',
        method: isEmail,
        validWhen: true,
        error: 'Enter a valid email',
      },
      {
        key: 'firstName',
        defaultValue: '',
        method: isEmpty,
        validWhen: false,
        error: 'Enter a valid first name',
      },
      {
        key: 'lastName',
        defaultValue: '',
        method: isEmpty,
        validWhen: false,
        error: 'Enter a valid last name',
      },
      {
        key: 'organization',
        defaultValue: '',
        method: isEmpty,
        validWhen: false,
        error: 'Enter a valid organization',
      },
    ];

    this.validator = new FormValidator(items);
  }

  onSubmit(e) {
    const { email, firstName, lastName, organization } = this.state;

    if (e) e.preventDefault();

    const validateAll = this.validator.validateAll({
      email,
      firstName,
      lastName,
      organization,
    });

    const formattedValidation = validationFormatter(validateAll);

    this.setState({
      feedback: {
        title: 'Check your information',
        text: formattedValidation.list(),
        kind: FeedbackKind.Error,
      },
    });

    if (!validateAll.isValid) return;

    let that = this;
    let response = { loading: true, error: null, feedback: null };

    this.setState({ ...response });

    signUp({ email, firstName, lastName, organization })
      .then(() => {
        response = {
          ...response,
          email: '',
          firstName: '',
          lastName: '',
          organization: '',
          feedback: {
            title: 'Success',
            text:
              'Thank you for signing up! Once the administrator approves your request, we will send you an email.',
          },
        };
      })
      .catch(({ response: { data } }) => {
        // To-do (P2): Check status code; ideally in a middleware?
        response.feedback = {
          title: StatusMessages.Error.Generic,
          text:
            (data && data.message) ||
            'Check your information and try again later.',
          kind: FeedbackKind.Error,
        };
      })
      .finally(() => {
        response.loading = false;
        that.setState({ ...response });
      });
  }

  render() {
    const {
      email,
      firstName,
      lastName,
      organization,
      loading,
      feedback,
    } = this.state;
    const { className } = this.props;

    const componentClasses = classNames(styles.signUp, {
      [className]: className,
    });

    return (
      <form className={componentClasses} onSubmit={(e) => this.onSubmit(e)}>
        <Helmet>
          <title>Create Your Account</title>
        </Helmet>

        <Input
          placeholder="Email"
          type="email"
          icon={UserIcon}
          onChange={(email) => this.setState({ email })}
          value={email}
        />
        <div className={styles.signUp__row}>
          <Input
            placeholder="First Name"
            onChange={(firstName) => this.setState({ firstName })}
            value={firstName}
          />
          <Input
            placeholder="Last Name"
            onChange={(lastName) => this.setState({ lastName })}
            value={lastName}
          />
        </div>
        <Input
          placeholder="Organization"
          type="text"
          icon={OrganizationIcon}
          onChange={(organization) => this.setState({ organization })}
          value={organization}
        />
        <Button
          className={styles.signIn__button}
          loading={loading}
          type="submit"
        >
          <UserPlusIcon className={styles.button__icon} />
          <span>Sign Up</span>
        </Button>
        {feedback && <Feedback {...feedback} />}
      </form>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default SignUp;
