//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Crest extends React.Component {
  render() {
    const { className, filled } = this.props;

    const componentClasses = classNames(styles.crest, {
      [className]: className,
    });

    return (
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 288 374"
        className={componentClasses}
      >
        <g>
          {filled && (
            <>
              <circle className="crest--fill" cx="45" cy="60" r="30" />
              <circle className="crest--fill" cx="245" cy="60" r="30" />
              <circle className="crest--fill" cx="145" cy="130" r="130" />
              <circle className="crest--fill" cx="145" cy="210" r="130" />
            </>
          )}
          <path
            className="crest--outline"
            d="M118.5 1.5C82 4.4 55.8 9.5 24.1 19.9 5.6 26 2.1 27.7 1.2 31c-2.1 7.4.8 161.4 3.4 180.2 3 22.4 7.8 37.7 18.6 59.2 14.3 28.5 29.3 47.4 54.3 68.5 15.1 12.8 33.9 23.9 53.2 31.5 11.1 4.4 15.1 4.3 28.3-1.1 45.5-18.4 83.3-55.2 108.3-105 5.8-11.5 12.6-33 15.2-48 2.2-12.9 2.4-17.8 3.5-75.3 1.7-93.2 2-114.3 1.4-116.2-1.4-4.5-48.7-16.4-82.7-20.7-31.4-4.1-57-4.9-86.2-2.6zM163.6 38c27.6 1.9 59 6.6 79.5 12.1 6.7 1.8 7.6 2.3 7.3 4.2-.2 1.2-.8 33-1.4 70.7-1.1 65.4-1.3 69.1-3.6 81.6-4.8 26.7-14.8 48.2-34.3 74.1-15.8 21-37.8 39.4-60 49.9l-7.3 3.5-5.2-2.1c-31.4-13.5-64.6-46.1-81.9-80.5C43 224 40.4 208.6 39 145.8c-.5-24.4-1.2-54.5-1.6-67l-.7-22.6 9.9-3.1c26.1-7.9 49-12 81.9-14.6 8.8-.7 16.7-1.3 17.6-1.4.9 0 8.7.4 17.5.9z"
          />
        </g>
      </svg>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Crest;
