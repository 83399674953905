//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import {
  FaHome as HomeIcon,
  FaNewspaper as NewspaperIcon,
  FaUpload as UploadIcon,
  FaCheck as CheckIcon,
  FaUsers as UsersIcon,
  FaQuestion as QuestionIcon,
  FaStar as PopularIcon,
  FaChartLine as DashboardIcon,
} from 'react-icons/fa';
//------------------------------------------------------------------------------
// Pages -----------------------------------------------------------------------
import Home from '@pages/authenticated/home';
import Profile from '@pages/authenticated/profile';
import Posts from '@pages/authenticated/posts';
import Publish from '@pages/authenticated/publish';
import Users from '@pages/authenticated/users';
import Create from '@pages/authenticated/create';
import Dashboard from '@pages/authenticated/dashboard';
import Popular from '@pages/authenticated/popular';
import Gallery from '@pages/authenticated/gallery';
import Refresh from '@pages/authenticated/refresh';
//------------------------------------------------------------------------------
// Helpers & Constants ---------------------------------------------------------
import { UserRoles } from '@helpers/constants/user';
import { userFormatter } from '@helpers/formatter';
//------------------------------------------------------------------------------
// Constants -------------------------------------------------------------------
const Route = (user) => ({
  Home: {
    title: 'Home Page',
    icon: HomeIcon,
    roles: [
      UserRoles.USER,
      UserRoles.PUBLISHER,
      UserRoles.ADMIN,
      UserRoles.SUPER_ADMIN,
    ],
    link: '/',
    path: '/*',
    exact: true,
    component: Home,
  },
  Posts: {
    title: 'My Posts',
    icon: NewspaperIcon,
    roles: [
      UserRoles.USER,
      UserRoles.PUBLISHER,
      UserRoles.ADMIN,
      UserRoles.SUPER_ADMIN,
    ],
    link: '/posts',
    path: '/posts',
    exact: true,
    component: Posts,
  },
  Create: {
    title: 'Upload',
    icon: UploadIcon,
    roles: [
      UserRoles.USER,
      UserRoles.PUBLISHER,
      UserRoles.ADMIN,
      UserRoles.SUPER_ADMIN,
    ],
    link: '/create',
    path: '/create',
    exact: true,
    component: Create,
  },
  Edit: {
    roles: [
      UserRoles.USER,
      UserRoles.PUBLISHER,
      UserRoles.ADMIN,
      UserRoles.SUPER_ADMIN,
    ],
    path: '/edit/:id',
    component: Create,
  },
  Profile: {
    roles: [
      UserRoles.USER,
      UserRoles.PUBLISHER,
      UserRoles.ADMIN,
      UserRoles.SUPER_ADMIN,
    ],
    link: '/profile',
    path: '/profile',
    exact: true,
    component: Profile,
  },
  Gallery: {
    roles: [
      UserRoles.USER,
      UserRoles.PUBLISHER,
      UserRoles.ADMIN,
      UserRoles.SUPER_ADMIN,
    ],
    path: '/post/:id/gallery',
    component: Gallery,
  },
  Publish: {
    title: 'Publish',
    icon: CheckIcon,
    roles: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
    link: '/publish',
    path: '/publish/*',
    component: Publish,
  },
  Users: {
    title: 'User Access',
    icon: UsersIcon,
    roles: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
    link: '/users',
    path: '/users/*',
    component: Users,
  },
  Dashboard: {
    title: 'Dashboard',
    icon: DashboardIcon,
    roles: [UserRoles.PUBLISHER, UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
    link: '/dashboard',
    path: '/dashboard',
    exact: true,
    component: Dashboard,
  },
  Popular: {
    title: 'Most Popular',
    icon: PopularIcon,
    roles: [UserRoles.PUBLISHER, UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
    link: '/popular',
    path: '/popular',
    component: Popular,
  },
  Help: {
    title: 'Help',
    icon: QuestionIcon,
    roles: [
      UserRoles.USER,
      UserRoles.PUBLISHER,
      UserRoles.ADMIN,
      UserRoles.SUPER_ADMIN,
    ],
    customUrl: `mailto:ExclusiveManufaktur@Porsche.us?subject=Porsche Exclusive Gallery help needed: ${userFormatter(
      user
    ).fullName()}`,
  },
  Refresh: {
    title: 'Refresh',
    roles: [UserRoles.SUPER_ADMIN],
    path: '/refresh',
    exact: true,
    component: Refresh,
  },
});

export const Routes = (user) => {
  const UserRoute = Route(user);
  const allRouteKeys = Object.keys(UserRoute);

  const formattedUser = userFormatter(user);
  let finalRoutes = [];

  for (let i = 0; i < allRouteKeys.length; i++) {
    const routeKey = allRouteKeys[i];
    const routeValue = UserRoute[routeKey];

    const hasRole = routeValue.roles.reduce((val, acc) => {
      return val || formattedUser.hasRole(acc);
    }, false);

    if (hasRole) {
      finalRoutes.push(routeValue);
    }
  }

  return finalRoutes;
};
