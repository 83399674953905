//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import {
  FaExpandArrowsAlt as FullScreenIcon,
  FaEye as ViewsIcon,
  FaChevronLeft as ArrowLeftIcon,
  FaChevronRight as ArrowRightIcon,
  FaTimes as CloseIcon,
} from 'react-icons/fa';
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { PageControl, Image } from '@cmp/common';
import { Crest as CrestIcon } from '@cmp/common/icons';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { nounFormatter } from '@helpers/formatter';
import { requestFullScreen, exitFullScreen } from '@helpers/fullScreen';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Slideshow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentIndex: 0,
      fullScreen: false,
    };

    this.slideshowEl = React.createRef();
    this.onFullScreenClicked = this.onFullScreenClicked.bind(this);
    this.previousImage = this.previousImage.bind(this);
    this.nextImage = this.nextImage.bind(this);
  }

  imagesComponent() {
    const { images, defaultImage } = this.props;
    const { currentIndex, fullScreen } = this.state;

    const image =
      (images &&
        images.length > currentIndex &&
        images[currentIndex].imageUrl) ||
      defaultImage;

    return (
      <div className={styles.slideshow__images} ref={this.slideshowEl}>
        <Image src={image} className={styles.slideshow__image} alt="Current" />
        {fullScreen && (
          <CloseIcon
            className={styles.slideshow__exit_full_screen}
            onClick={() => this.onFullScreenClicked()}
          />
        )}
      </div>
    );
  }

  arrowsComponent() {
    const { images } = this.props;
    if (!images || images.length <= 1) return '';

    const { currentIndex } = this.state;

    const leftArrowClasses = classNames(
      styles.slideshow__arrow,
      styles.arrow__left,
      {
        [styles[`slideshow__arrow--hidden`]]: currentIndex <= 0,
      }
    );

    const rightArrowClasses = classNames(
      styles.slideshow__arrow,
      styles.arrow__right,
      {
        [styles[`slideshow__arrow--hidden`]]: currentIndex >= images.length - 1,
      }
    );

    return (
      <div className={styles.slideshow__arrows}>
        <button
          className={leftArrowClasses}
          onClick={this.previousImage}
          aria-label="Previous image"
          tabIndex={-1}
        >
          <ArrowLeftIcon className={styles.arrow__icon} />
        </button>
        <button
          className={rightArrowClasses}
          onClick={this.nextImage}
          aria-label="Next image"
          tabIndex={-1}
        >
          <ArrowRightIcon className={styles.arrow__icon} />
        </button>
      </div>
    );
  }

  previousImage() {
    const { currentIndex } = this.state;
    this.setState({ currentIndex: currentIndex - 1 });
  }

  nextImage() {
    const { currentIndex } = this.state;
    this.setState({ currentIndex: currentIndex + 1 });
  }

  detailsComponent() {
    const {
      likeCount,
      viewCount,
      images,
      defaultImage,
      liked,
      toggleLike,
    } = this.props;
    const hasImages = (images && images.length > 0) || defaultImage;

    function detailsItem(IconComponent, iconProps, text, onClick) {
      const itemClasses = classNames(styles.details__item, {
        [styles[`details__item--interactive`]]: onClick,
      });

      return (
        <button className={itemClasses} onClick={onClick}>
          <IconComponent className={styles.details__icon} {...iconProps} />
          <span className={styles.details__label}>{text}</span>
        </button>
      );
    }

    return (
      <div className={styles.slideshow__details}>
        {hasImages &&
          detailsItem(
            FullScreenIcon,
            {},
            'Full screen',
            this.onFullScreenClicked
          )}
        {likeCount >= 0 &&
          detailsItem(
            CrestIcon,
            { filled: liked },
            nounFormatter.idealForm('Like', 'Likes', likeCount, true),
            toggleLike
          )}
        {viewCount > 0 &&
          detailsItem(
            ViewsIcon,
            {},
            nounFormatter.idealForm('View', 'Views', viewCount, true)
          )}
      </div>
    );
  }

  onFullScreenClicked() {
    const { current } = this.slideshowEl;
    if (!current) return;

    const { fullScreen } = this.state;

    if (fullScreen) {
      exitFullScreen();
    } else {
      requestFullScreen(current);
    }

    this.setState({ fullScreen: !fullScreen });
  }

  render() {
    const { className, images } = this.props;
    const { currentIndex } = this.state;

    const componentClasses = classNames(styles.slideshow, {
      [className]: className,
    });

    return (
      <div className={componentClasses}>
        {this.detailsComponent()}
        {this.imagesComponent()}
        {this.arrowsComponent()}
        {images && images.length > 1 && (
          <PageControl
            current={currentIndex}
            total={images.length}
            className={styles.slideshow__page_control}
          />
        )}
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Slideshow;
