//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { PhotoUpload } from '@cmp/authenticated';
import { withAuthUser } from '@cmp/common/hoc';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { updateUser } from '@helpers/auth';
//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------
import { User as UserRequest } from '@api/endpoints/get';
import { updateProfilePicture as updateProfilePictureRequest } from '@api/endpoints/post';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      uploadingPicture: false,
    };
  }

  sectionComponent(title, content) {
    return (
      <section className={styles.profile__section}>
        <h2 className={styles.section__title}>{title}</h2>
        {content}
      </section>
    );
  }

  informationComponent(user) {
    // Since a row is only a part of the information component, I find that this
    // is the ideal place for them, inside the main component function.
    function informationRow(label, value) {
      return (
        <div className={styles.user_information__row}>
          <span className={styles.user_information__label}>{label}</span>
          <span className={styles.user_information__value}>{value}</span>
        </div>
      );
    }

    return (
      <div className={styles.section__information}>
        {informationRow('First Name', user.firstName)}
        {informationRow('Last Name', user.lastName)}
        {informationRow('Email', user.email)}
      </div>
    );
  }

  photoComponent(user) {
    const { uploadingPicture } = this.state;

    return (
      <PhotoUpload
        user={user}
        onConfirm={(image) => this.updateProfilePicture(image)}
        updating={uploadingPicture}
      />
    );
  }

  updateProfilePicture(profileImage) {
    let that = this;
    const { __authUser: { user: { id } = {} } = {} } = this.props;
    if (!id) return;

    // To-do (P1): Show error message
    this.setState({ uploadingPicture: true });

    updateProfilePictureRequest({ profileImage, id })
      .then(() => this.refetch())
      .finally(() => {
        that.setState({ uploadingPicture: false });
      });
  }

  render() {
    const { className, __authUser: { user } = {} } = this.props;
    if (!user) return '';

    const componentClasses = classNames(styles.profile, {
      [className]: className,
    });

    return (
      <UserRequest
        onComplete={(data) => updateUser(data)}
        stateIndicatorComponent={null}
        didUpdateRefetch={(refetch) => (this.refetch = refetch)}
      >
        {({ loading, error, data }) => {
          const idealUser = data || user;

          return (
            <div className={componentClasses}>
              <Helmet>
                <title>Profile</title>
              </Helmet>

              <h1 className={styles.profile__title}>Profile</h1>
              {this.sectionComponent(
                'Basic Information',
                this.informationComponent(idealUser)
              )}
              {this.sectionComponent('Picture', this.photoComponent(idealUser))}
            </div>
          );
        }}
      </UserRequest>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withAuthUser(Profile);
