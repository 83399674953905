//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import {
  FaChevronLeft as LeftIcon,
  FaChevronRight as RightIcon,
} from 'react-icons/fa';
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Input } from '@cmp/common';
//------------------------------------------------------------------------------
// Constants -------------------------------------------------------------------
//  Local ----------------------------------------------------------------------
const ArrowDirection = {
  LEFT: {
    icon: LeftIcon,
  },
  RIGHT: {
    icon: RightIcon,
  },
};
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Pagination extends React.Component {
  constructor(props) {
    super(props);

    // Depending on the use case, Pagination might be rebuilt when there's new
    // data from the API. If that's the case, start with the value passed via
    // props. If that doesn't exist, set it to 1.

    this.state = {
      value: (props.current && props.current.toString()) || '1',
    };

    this.validateAndSubmit = this.validateAndSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { current } = this.props;

    // If the current page prop change, make sure to update the input value.

    if (prevProps.current !== current) {
      this.setState({ value: current.toString() });
    }
  }

  arrowComponent({ icon: IconComponent }, disabled, onClick) {
    const componentClasses = classNames(
      styles.pagination__item,
      styles.pagination__arrow,
      {
        [styles[`pagination__item--disabled`]]: disabled,
      }
    );

    return (
      <button className={componentClasses} onClick={onClick} tabIndex={-1}>
        <IconComponent />
      </button>
    );
  }

  validateAndSubmit(e) {
    e.preventDefault();

    const { current, total, onChange } = this.props;
    const { value } = this.state;

    let selectedPage = parseInt(value);

    // First, check if the value, parsed as an integer, is in fact a number.
    if (isNaN(selectedPage)) {
      // If it isn't, reset the value of the input.
      this.setState({ value: current });
      return;
    }

    // Set a lower and upper bound for the page
    selectedPage = Math.max(1, selectedPage);
    selectedPage = Math.min(selectedPage, total);

    // Call the props.onChange method
    if (onChange) onChange(selectedPage);
  }

  toggleComponent() {
    const { value } = this.state;
    const { total } = this.props;

    return (
      <div className={styles.pagination__toggle}>
        <form onSubmit={this.validateAndSubmit}>
          <Input
            className={styles.toggle__input}
            value={value}
            onChange={(value) => this.setState({ value })}
          />
        </form>
        <span className={styles.toggle__total}>of {total}</span>
      </div>
    );
  }

  render() {
    const { className, first, last, current, disabled, onChange } = this.props;

    const componentClasses = classNames(styles.pagination, {
      [styles[`pagination--disabled`]]: disabled,
      [className]: className,
    });

    return (
      <div className={componentClasses}>
        {this.arrowComponent(ArrowDirection.LEFT, first, () =>
          onChange(current - 1)
        )}
        {this.toggleComponent()}
        {this.arrowComponent(ArrowDirection.RIGHT, last, () =>
          onChange(current + 1)
        )}
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Pagination;
