import moment from 'moment';

export const StatusCode = {
  Unauthorized: 401,
  Forbidden: 403,
};

export const StatusMessages = {
  Error: {
    Generic: 'Something went wrong.',
    Actionable: 'Something went wrong. Please try again.',
    Support: 'Something went wrong. Please contact our support team.',
  },
  Placeholder: 'Nothing yet.',
};

export const FormattedDate = (month, year) => {
  // January (month 0) would return here if we were to check if it's equal to
  // null; JavaScript... ¯\_(ツ)_/¯
  if (month === undefined || year === undefined) return;

  const date = moment({
    month,
    year,
  });

  return date.format('Y-MM');
};
