//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import axios from 'axios';
//------------------------------------------------------------------------------
// Interceptors ----------------------------------------------------------------
import {
  useAuthorizationInterceptor,
  useUnauthorizedInterceptor,
} from '@api/interceptors';
//------------------------------------------------------------------------------
// Client ----------------------------------------------------------------------
const client = axios.create({
  baseURL: process.env.API_ENDPOINT,
});

useAuthorizationInterceptor(client);
useUnauthorizedInterceptor(client);
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default client;
