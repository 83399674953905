//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { FaSpinner as SpinnerIcon } from 'react-icons/fa';
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------

//------------------------------------------------------------------------------
// Constants -------------------------------------------------------------------
export const ButtonKind = {
  Primary: 'primary',
  Secondary: 'secondary',
  Info: 'info',
  Interrupt: 'interrupt',
  Allow: 'allow',
};
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Button extends React.Component {
  render() {
    const {
      className,
      children,
      onClick,
      type = 'button',
      kind = ButtonKind.Primary,
      loading,
      disabled,
    } = this.props;

    const componentClasses = classNames(styles.button, {
      [styles[`button--${kind}`]]: kind,
      [styles['button--loading']]: loading,
      [styles['button--disabled']]: loading || disabled,
      [className]: className,
    });

    return (
      <button className={componentClasses} onClick={onClick} type={type}>
        {loading ? (
          <SpinnerIcon className={styles.button__spinner} />
        ) : (
          children
        )}
      </button>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Button;
