//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Modal } from '@cmp/common';
import { TextArea } from '@cmp/common';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class ModalTextArea extends React.Component {
  render() {
    const { value, onChange, onClose, ...rest } = this.props;

    return (
      <Modal.Generic
        className={styles.text_area_modal}
        onClose={onClose}
        {...rest}
      >
        <TextArea value={value} onChange={onChange} />
      </Modal.Generic>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default ModalTextArea;
