//------------------------------------------------------------------------------
// Node Modules ---------------------------------------------------------
import { defaultClient, credentialedClient } from '@api/client';
//------------------------------------------------------------------------------
// Requests --------------------------------------------------------------------
export const authenticate = (accessToken) => {
  return defaultClient.post('/authenticate', {
    accessToken,
  });
};

export const signUp = ({ email, firstName, lastName, organization }) => {
  return defaultClient.post('/register', {
    email,
    firstName,
    lastName,
    organization,
  });
};

export const forgotPassword = ({ email }) => {
  return defaultClient.post('/forgotPassword', {
    email,
  });
};

export const updatePassword = ({
  token: updatedPasswordToken,
  password: updatedPassword,
}) => {
  return defaultClient.post('/updatePassword', {
    updatedPassword,
    updatedPasswordToken,
  });
};
// -----------------------------------------------------------------------------
// Credentials -----------------------------------------------------------------
// These requests require the refresh_token cookie. It looks like a customized
// axios client (such as defaultClient or retryClient) don't work properly with
// the isCredentials property.
export const signIn = ({ email: username, password }) => {
  return credentialedClient.post('/authenticate', {
    username,
    password,
  });
};

export const refresh = () => {
  return credentialedClient.post('/authenticate/refresh');
};

export const signOut = () => {
  return credentialedClient.post('/logout');
};
