//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { Router, Redirect, Location } from '@reach/router';
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import All from './all';
import TopContributors from './topContributors';
import TopPosts from './topPosts';
import Create from './create';
import Pending from './pending';
import { PageSwitch } from '@cmp/authenticated';
//------------------------------------------------------------------------------
// Routes ----------------------------------------------------------------------
const Routes = [
  { path: '/', component: All },
  { path: 'pending', component: Pending },
  { path: 'contributors', component: TopContributors },
  { path: 'posts', component: TopPosts },
  {
    path: ':id/edit',
    component: Create,
    regularExpression: `.+/edit`,
    fullScreen: true,
  },
  { path: 'create', component: Create, fullScreen: true },
];
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Users extends React.Component {
  render() {
    const { className, location: parentLocation } = this.props;

    const componentClasses = classNames(styles.users, {
      [className]: className,
    });

    return (
      <div className={componentClasses}>
        <Location>
          {({ location }) => {
            const fullScreenRoutes = Routes.filter((x) => x.fullScreen);
            const isFullScreen = fullScreenRoutes.find(
              ({ path, regularExpression }) =>
                new RegExp(regularExpression || path).test(location.pathname)
            );

            return (
              <>
                {!isFullScreen && (
                  <PageSwitch
                    pages={[
                      { exact: true, to: '/users', title: 'All Users' },
                      { exact: true, to: '/users/pending', title: 'Pending' },
                      {
                        exact: true,
                        to: '/users/contributors',
                        title: 'Top Contributors',
                      },
                      { exact: true, to: '/users/posts', title: 'Top Posts' },
                    ]}
                  />
                )}
                <div className={styles.publish__page}>
                  <Router location={parentLocation}>
                    {Routes.map(({ component: Component, path }) => (
                      <Component path={path} key={path} />
                    ))}
                    <Redirect from="*" to="/users" noThrow />
                  </Router>
                </div>
              </>
            );
          }}
        </Location>
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Users;
