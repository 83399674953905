//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------
import { likePost } from '@api/endpoints/post';
import { unlikePost } from '@api/endpoints/delete';
//------------------------------------------------------------------------------
// Constants -------------------------------------------------------------------
export const PostStatus = {
  CREATED: 'CREATED',
  PUBLISHED: 'PUBLISHED',
  DENIED: 'DENIED',
};

export const DetailedPostStatus = ({ postStatus: status, denyMessage }) => {
  if (status === PostStatus.CREATED) {
    return { formatted: 'Created' };
  }

  if (status === PostStatus.PUBLISHED) {
    return { formatted: 'Published' };
  }

  if (status === PostStatus.DENIED) {
    return {
      formatted: 'Denied',
      modal: {
        title: 'Your post was denied',
        description: (
          <span>
            An administrator of the platform denied your post, and gave the
            following reason: <i>{denyMessage}</i>.
          </span>
        ),
      },
    };
  }
};

export const PostAction = {
  Like: {
    method: likePost,
    transform: (post) => {
      post.loginUserLiked = true;
      post.likes = post.likes + 1;
      return post;
    },
  },
  Unlike: {
    method: unlikePost,
    transform: (post) => {
      post.loginUserLiked = false;
      post.likes = post.likes - 1;
      return post;
    },
  },
};
