//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Tags extends React.Component {
  constructor(props) {
    super(props);
    this.tagComponent = this.tagComponent.bind(this);
  }

  tagComponent(tag) {
    return (
      <div className={styles.tags__item} key={tag}>
        <span className={styles.item__text}>{tag}</span>
      </div>
    );
  }

  render() {
    const { className } = this.props;
    const tags = [
      'GT3 RS',
      'Chalk on Chalk',
      '911 Turbo S',
      'Exclusive Content',
      'Superior Red Metallic',
      'Cayenne Turbo',
    ];

    if (!tags || tags.length <= 0) return '';

    const componentClasses = classNames(styles.tags, {
      [className]: className,
    });

    return (
      <div className={componentClasses}>
        {/* Repeating this a couple times so that it overflows (for demo
            purposes) */}
        {tags.map(this.tagComponent)}
        {tags.map(this.tagComponent)}
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Tags;
