// PhotoUpload -----------------------------------------------------------------
// A photo upload component that supports file selection and dragging and
// dropping; ideally, it should be agnostic to what the parent will do with the
// selected file, but for now it presents the PhotoEditor (comments on its
// files).
//
// When completely integrated, the component must return the final selected
// image.
//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import prettyBytes from 'pretty-bytes';
// import { FaTimes as RemoveIcon } from 'react-icons/fa';
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// Assets ----------------------------------------------------------------------
import { UserPlaceholderImage } from '@img/common';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Button, ButtonKind, Image } from '@cmp/common';
import { PhotoEditor } from '@cmp/authenticated';
//------------------------------------------------------------------------------
// Helpers & Constants ---------------------------------------------------------
import {
  ImageTypes,
  ImageTypeLimit,
  ImageTypesFormatted,
} from '@helpers/constants/file';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class PhotoUpload extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFile: null,
      highlighted: false,
    };

    // Creating references to the upload component (used for the drag and drop
    // logic), and the actual file input tag (so we can hide it and simulate its
    // behavior with a custom component).
    this.uploadComponent = React.createRef();
    this.fileInput = React.createRef();

    // Set up drag listeners
    this.setupListeners = this.setupListeners.bind(this);

    // Drag and drop methods used to highlight, unhighlight and handle what's
    // dropped in the component
    this.highlight = this.highlight.bind(this);
    this.unhighlight = this.unhighlight.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.preventDefault = this.preventDefault.bind(this);
  }

  toggleInput() {
    // Using the file input reference created above, we simulate a click in it
    if (!this.fileInput || !this.fileInput.current) return;
    this.fileInput.current.click();
  }

  componentDidMount() {
    this.setupListeners();
  }

  setupListeners() {
    if (!this.uploadComponent || !this.uploadComponent.current) return;

    // Used to organize the names of the listeners and its methods (so it's
    // easier to read and understand the code)
    const listeners = [
      { name: 'dragenter', methods: [this.highlight, this.preventDefault] },
      { name: 'dragleave', methods: [this.unhighlight, this.preventDefault] },
      { name: 'dragover', methods: [this.highlight, this.preventDefault] },
      {
        name: 'drop',
        methods: [this.unhighlight, this.preventDefault, this.handleDrop],
      },
    ];

    // To-do (P0): Remove listeners on unmount
    listeners.forEach((listener) => {
      listener.methods.forEach((method) => {
        this.uploadComponent.current.addEventListener(
          listener.name,
          method,
          false
        );
      });
    });
  }

  highlight() {
    this.setState({ highlighted: true });
  }

  unhighlight() {
    this.setState({ highlighted: false });
  }

  preventDefault(e) {
    // Preventing the default behavior and stopping the propagation when dragging
    // and dropping files, which for some browsers is to open it replacing the
    // current tab
    e.preventDefault();
    e.stopPropagation();
  }

  handleDrop({ dataTransfer: { files } = {} } = {}) {
    // handleDrop is called from two places:
    // 1. input.onChange: the first argument is an event (meaning that there's no
    //    dataTransfer available). If that's the case, try reading them from the
    //    input reference.
    // 2. drop (event): dataTransfer is available
    if (!files) files = this.fileInput.current.files;

    // If there are fewer than or more than one file, ignore it. As a paranoid
    // safety measure, we're not using equal comparsions.
    if (files.length < 1 || files.length > 1) return;

    // Get the actual file, and see if its type is supported and if the size is
    // less than the limit
    const imageFile = files[0];
    if (ImageTypes.findIndex((x) => x.type === imageFile.type) <= -1) return;
    if (imageFile.size > ImageTypeLimit) return;

    // Update the state with the selected file
    this.setState({ selectedFile: imageFile });
  }

  editComponent() {
    const { className, onConfirm } = this.props;
    const { selectedFile } = this.state;

    const componentClasses = classNames(styles.photo_editor, {
      [className]: className,
    });

    return (
      <div className={componentClasses}>
        {/* As mentioned above, this _should_ be handled by the parent.
            PhotoUpload must be used just to handle the file upload. For now,
            though it'll also hand out the selected file already cropped and
            scaled based on the user's selection */}
        <PhotoEditor
          file={selectedFile}
          className={styles.photo_editor__component}
          canvasRef={(canvasRef) => (this.canvasRef = canvasRef)}
        />
        <div className={styles.photo_editor__actions}>
          {/* When clicking to set the picture, get whatever is in the
              PhotoEditor canvas */}
          <Button
            kind={ButtonKind.Interrupt}
            onClick={() =>
              this.setState({ selectedFile: null }, this.setupListeners)
            }
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              this.canvasRef.current.toBlob(onConfirm);
              this.setState({ selectedFile: null });
            }}
          >
            Set Picture
          </Button>
        </div>
      </div>
    );
  }

  selectComponent() {
    const { className, user, updating } = this.props;
    const { highlighted } = this.state;

    // Joining all accepted types so we can pass it to the input element
    const acceptedTypes = ImageTypes.map((x) => x.extension).join(',');

    const componentClasses = classNames(styles.photo_upload, {
      // Using classNames, add the highlighted modified to the class if needed
      [styles[`photo_upload--highlighted`]]: highlighted,
      [className]: className,
    });

    return (
      <form
        className={componentClasses}
        onSubmit={(e) => e.preventDefault()}
        ref={this.uploadComponent}
      >
        {/* Hidden input */}
        <input
          type="file"
          className={styles.photo_upload__input}
          accept={acceptedTypes}
          ref={this.fileInput}
          onChange={() => this.handleDrop()}
        />
        {/* The current profile image of the user */}
        <Image
          src={user.profileImageUrl}
          customPlaceholder={UserPlaceholderImage}
          className={styles.photo__current}
        />
        <div className={styles.photo__update}>
          {/* Button that simulates the hidden input */}
          <Button
            className={styles.photo__upload}
            onClick={() => this.toggleInput()}
            loading={updating}
          >
            Upload Picture
          </Button>
          <div className={styles.photo__disclaimer}>Or drop the image here</div>
          {/* To-do (P2): Get the file types from the constant */}
          <div className={styles.photo__limits}>
            {prettyBytes(ImageTypeLimit)} or smaller, {ImageTypesFormatted()}
          </div>
        </div>
      </form>
    );
  }

  render() {
    const { selectedFile } = this.state;
    return selectedFile ? this.editComponent() : this.selectComponent();
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default PhotoUpload;
