//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import GET from '@api/methods/get';
//------------------------------------------------------------------------------
// Endpoint --------------------------------------------------------------------
const Request = (id) => ({
  endpoint: `/admin/users/${id}`,
});
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
export const AdminUser = ({ children, id, ...props }) => {
  return (
    <GET request={Request(id)} {...props}>
      {children}
    </GET>
  );
};
