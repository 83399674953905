//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { FaTimes as RemoveIcon } from 'react-icons/fa';
import prettyBytes from 'pretty-bytes';
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Button, ButtonKind } from '@cmp/common';
//------------------------------------------------------------------------------
// Assets ----------------------------------------------------------------------
import { PostPlaceholder } from '@img/posts';
//------------------------------------------------------------------------------
// Helpers & Constants ---------------------------------------------------------
import {
  ImageTypes,
  ImageTypesFormatted,
  ImageTypeLimit,
} from '@helpers/constants/file';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class ImageSelector extends React.Component {
  constructor(props) {
    super(props);
    this.fileInput = React.createRef();
    this.itemComponent = this.itemComponent.bind(this);
    this.onFilesChange = this.onFilesChange.bind(this);
  }

  setupFileReader(file, ref) {
    const reader = new FileReader();
    reader.onload = ({ target: { result } }) => {
      let component = ref.current;
      if (!component) return;

      component.src = result;
    };
    reader.readAsDataURL(file);
  }

  itemComponent(item) {
    const imageComponent = React.createRef();
    const { disabled = [], onRemove } = this.props;

    const isFile = item instanceof File;
    const isDisabled = disabled.indexOf(item) > -1;

    if (isFile) {
      this.setupFileReader(item, imageComponent);
    }

    const removeClasses = classNames(styles.item__remove, {
      [styles[`item__remove--disabled`]]: isDisabled,
    });

    return (
      <div
        className={styles.images__item}
        key={item.id || item.name + item.lastModified}
      >
        <img
          src={(!isFile && item.imageUrl) || PostPlaceholder}
          className={styles.item__image}
          alt="Post preview"
          ref={imageComponent}
        />

        <RemoveIcon
          className={removeClasses}
          onClick={() => onRemove(item, isFile)}
        />
      </div>
    );
  }

  onFilesChange() {
    if (!this.fileInput) return;

    const { files } = this.fileInput.current;
    const { onSelect } = this.props;

    // Convert FileList to array
    let filesArray = Array.from(files);

    // Remove files over the limit
    filesArray = filesArray.filter((x) => x.size <= ImageTypeLimit);

    // If any are remaining, pass it to the parent
    if (filesArray.length > 0) onSelect(filesArray);
  }

  render() {
    const { className, files, multiple } = this.props;

    const componentClasses = classNames(styles.image_selector, {
      [className]: className,
    });

    // Joining all accepted types so we can pass it to the input element
    const acceptedTypes = ImageTypes.map((x) => x.extension).join(',');

    return (
      <div className={componentClasses}>
        <div className={styles.images}>
          {files && files.map(this.itemComponent)}
        </div>
        <input
          type="file"
          ref={this.fileInput}
          className={styles.images__input}
          onChange={this.onFilesChange}
          multiple={multiple}
          accept={acceptedTypes}
        />
        <span className={styles.image__limits}>
          {prettyBytes(ImageTypeLimit)} or smaller, {ImageTypesFormatted()}
        </span>
        <Button
          kind={ButtonKind.Info}
          type="button"
          onClick={() => this.fileInput.current.click()}
        >
          Add image
        </Button>
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default ImageSelector;
