//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import axios from 'axios';
//------------------------------------------------------------------------------
// Interceptors ----------------------------------------------------------------
import {
  useAuthorizationInterceptor,
  useUnauthorizedInterceptor,
  useRetryInterceptor,
} from '@api/interceptors';
//------------------------------------------------------------------------------
// Client ----------------------------------------------------------------------
const retryClient = axios.create({
  baseURL: process.env.API_ENDPOINT,
});

useAuthorizationInterceptor(retryClient);
useUnauthorizedInterceptor(retryClient);
useRetryInterceptor(retryClient);
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default retryClient;
