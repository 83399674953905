//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import {
  FaThumbsUp as ApproveIcon,
  FaThumbsDown as RejectIcon,
  FaEye as ViewIcon,
  FaPen as EditIcon,
  FaTrashAlt as DeleteIcon,
} from 'react-icons/fa';
import { navigate } from 'gatsby';
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from './index.scss';
import sharedStyles from '@styles/common/_shared.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Button, ButtonKind, Image, Modal } from '@cmp/common';
import { AuthenticatedPage } from '@cmp/authenticated';
//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------
import { Posts as PostsRequest } from '@api/endpoints/get';
//------------------------------------------------------------------------------
// Helpers & Constants ---------------------------------------------------------
//  External -------------------------------------------------------------------
import { PostStatus } from '@helpers/constants/post';
import { StatusMessages } from '@helpers/constants/api';
import { approvePost, denyPost } from '@api/endpoints/put';
import { deletePost } from '@api/endpoints/delete';
//  Local ----------------------------------------------------------------------
const ModalKind = {
  Deny: 'DENY',
  DeleteConfirmation: 'CONFIRMATION',
};
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Pending extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      updating: [],
      modal: {},
    };

    this.rowComponent = this.rowComponent.bind(this);
  }

  headerComponent() {
    return (
      <thead className={sharedStyles.table__header}>
        <tr className={sharedStyles.header__row}>
          <th>Thumbnail</th>
          <th>Title</th>
          <th aria-label="Actions"></th>
        </tr>
      </thead>
    );
  }

  rowComponent(item) {
    const { location } = this.props;

    const { updating } = this.state;
    const updatingItem = updating.findIndex((x) => x.id === item.id) > -1;

    const componentClasses = classNames(sharedStyles.table__row, {
      [sharedStyles[`table__row--disabled`]]: updatingItem,
    });

    return (
      <tr className={componentClasses} key={item.id}>
        <td
          className={classNames(
            sharedStyles.row__data,
            sharedStyles.row__thumbnail
          )}
        >
          <Image alt="Post thumbnail" src={item.titleImageUrl} />
        </td>
        <td className={sharedStyles.row__data}>{item.title}</td>
        <td className={sharedStyles.row__data}>
          <div className={sharedStyles.row__actions}>
            <Button
              kind={ButtonKind.Interrupt}
              onClick={() =>
                navigate(`/post/${item.id}`, {
                  state: { modal: true, from: location, post: item },
                })
              }
            >
              <ViewIcon />
            </Button>
            <Button
              kind={ButtonKind.Info}
              onClick={() =>
                navigate(`/edit/${item.id}`, { state: { post: item } })
              }
            >
              <EditIcon />
            </Button>
            <Button
              kind={ButtonKind.Primary}
              disabled={updatingItem}
              onClick={() =>
                this.setState({
                  modal: { kind: ModalKind.Deny, data: { post: item } },
                })
              }
            >
              <RejectIcon />
            </Button>
            <Button
              kind={ButtonKind.Allow}
              disabled={updatingItem}
              onClick={() => this.updateItem(approvePost, item)}
            >
              <ApproveIcon />
            </Button>
            <Button
              kind={ButtonKind.Secondary}
              disabled={updatingItem}
              onClick={() =>
                this.setState({
                  modal: {
                    kind: ModalKind.DeleteConfirmation,
                    data: { post: item },
                  },
                })
              }
            >
              <DeleteIcon />
            </Button>
          </div>
        </td>
      </tr>
    );
  }

  // All action buttons (that make network requests) use this single entry
  // point. It's a shared way to add the item to the updating list, call the
  // endpoint and refetch the whole list afterwards.
  updateItem(method, item, payload) {
    let { updating } = this.state;

    if (updating.findIndex((x) => x.id === item.id) <= -1) {
      updating.push(item);
    }

    this.setState({ updating, modal: {} });

    method(item, payload)
      .then(() => this.refetch())
      .catch((err) => {
        // If something goes wrong, enable all buttons again. We could reuse the
        // index from above, but in case the request takes a while and the user
        // is clicking to update multiple posts, that might be out of date.
        const indexOf = updating.findIndex((x) => x.id === item.id);
        if (indexOf <= -1) return;

        updating.splice(indexOf, 1);
        this.setState({ updating });
      });
  }

  tableComponent(data = {}) {
    const { content } = data || {};
    if (!content || content.length <= 0) return '';

    return (
      <div className={sharedStyles.table_wrapper}>
        <table
          className={classNames(styles.pending__table, sharedStyles.table)}
        >
          {this.headerComponent()}
          <tbody className={sharedStyles.table__body}>
            {content.map(this.rowComponent)}
          </tbody>
        </table>
      </div>
    );
  }

  denialReasonComponent() {
    const { modal } = this.state;
    if (modal.kind !== ModalKind.Deny) return '';

    const { data = {} } = modal;

    return (
      <Modal.TextArea
        title="Please enter a deny reason"
        actions={[
          {
            title: 'Cancel',
            onClick: () => this.setState({ modal: {} }),
            kind: ButtonKind.Interrupt,
          },
          {
            title: 'Deny',
            onClick: () => this.updateItem(denyPost, data.post, data.value),
            disabled: !data.value || data.value.length <= 0,
          },
        ]}
        value={data.value || ''}
        onChange={(value) => {
          data.value = value;
          modal.data = data;

          this.setState({ modal });
        }}
        onClose={() => this.setState({ modal: {} })}
      />
    );
  }

  deleteConfirmationComponent() {
    const { modal } = this.state;
    if (modal.kind !== ModalKind.DeleteConfirmation) return '';

    const { data = {} } = modal;

    return (
      <Modal.Generic
        title="Delete Post"
        description={`Do you want to delete the post by ${data.post.user.firstName}? This action can't be undone.`}
        actions={[
          {
            title: 'Cancel',
            onClick: () => this.setState({ modal: {} }),
            kind: ButtonKind.Interrupt,
          },
          {
            title: 'Delete',
            onClick: () => this.updateItem(deletePost, data.post),
            kind: ButtonKind.Info,
          },
        ]}
        onClose={() => this.setState({ modal: {} })}
      />
    );
  }

  render() {
    const { className } = this.props;

    const componentClasses = classNames(styles.pending, {
      [className]: className,
    });

    return (
      <AuthenticatedPage title="Publish" className={componentClasses}>
        {/* Posts table ---------------------------------------------------- */}
        <PostsRequest
          postStatus={PostStatus.CREATED}
          validPlaceholder={({ empty } = {}) =>
            empty && StatusMessages.Placeholder
          }
          didUpdateRefetch={(refetch) => (this.refetch = refetch)}
        >
          {({ data }) => this.tableComponent(data)}
        </PostsRequest>

        {/* Deny modal ----------------------------------------------------- */}
        {this.denialReasonComponent()}

        {/* Delete Confirmation modal -------------------------------------- */}
        {this.deleteConfirmationComponent()}
      </AuthenticatedPage>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Pending;
