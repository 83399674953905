//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import GET from '@api/methods/get';
//------------------------------------------------------------------------------
// Endpoint --------------------------------------------------------------------
const Request = {
  endpoint: `/admin/post-views`,
};
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
export const AdminPostViews = ({ children, ...props }) => {
  return (
    <GET request={Request} {...props}>
      {children}
    </GET>
  );
};
