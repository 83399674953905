import { defaultClient } from '@api/client';

export const createPostImage = (post, file) => {
  if (!post) return;

  let formData = new FormData();
  formData.append('image', file);
  formData.append('postId', post.id);

  return defaultClient.post('/postImages', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};
