//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import {
  FaEye as ViewIcon,
  FaPen as EditIcon,
  FaTrashAlt as DeleteIcon,
  FaCheck as ApproveIcon,
} from 'react-icons/fa';
import { navigate } from 'gatsby';
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from './index.scss';
import sharedStyles from '@styles/common/_shared.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Button, ButtonKind, Image, Modal } from '@cmp/common';
import { AuthenticatedPage } from '@cmp/authenticated';
//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------
import { Posts as PostsRequest } from '@api/endpoints/get';
import { approvePost } from '@api/endpoints/put';
import { deletePost } from '@api/endpoints/delete';
//------------------------------------------------------------------------------
// Helpers & Constants ---------------------------------------------------------
//  External -------------------------------------------------------------------
import { PostStatus } from '@helpers/constants/post';
import { StatusMessages } from '@helpers/constants/api';
//  Local ----------------------------------------------------------------------
const ModalKind = {
  DeleteConfirmation: 'CONFIRMATION',
  Error: 'ERROR',
};
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Denied extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      updating: [],
      modal: {},
    };

    this.rowComponent = this.rowComponent.bind(this);
  }

  headerComponent() {
    return (
      <thead className={sharedStyles.table__header}>
        <tr className={sharedStyles.header__row}>
          <th>Thumbnail</th>
          <th>Title</th>
          <th>Deny message</th>
          <th aria-label="Actions"></th>
        </tr>
      </thead>
    );
  }

  rowComponent(item) {
    const { location } = this.props;

    const { updating } = this.state;
    const updatingItem = updating.findIndex((x) => x.id === item.id) > -1;

    return (
      <tr className={sharedStyles.table__row} key={item.id}>
        <td
          className={classNames(
            sharedStyles.row__data,
            sharedStyles.row__thumbnail
          )}
        >
          <Image alt="Post thumbnail" src={item.titleImageUrl} />
        </td>
        <td className={sharedStyles.row__data}>{item.title}</td>
        <td className={sharedStyles.row__data}>{item.denyMessage}</td>
        <td className={sharedStyles.row__data}>
          <div className={sharedStyles.row__actions}>
            <Button
              kind={ButtonKind.Interrupt}
              onClick={() =>
                navigate(`/post/${item.id}`, {
                  state: { modal: true, from: location, post: item },
                })
              }
            >
              <ViewIcon />
            </Button>
            <Button
              kind={ButtonKind.Info}
              onClick={() =>
                navigate(`/edit/${item.id}`, { state: { post: item } })
              }
            >
              <EditIcon />
            </Button>
            <Button
              kind={ButtonKind.Primary}
              onClick={() =>
                this.setState({
                  modal: { kind: ModalKind.DeleteConfirmation, data: item },
                })
              }
              disabled={updatingItem}
            >
              <DeleteIcon />
            </Button>
            <Button
              kind={ButtonKind.Allow}
              onClick={() => this.updateItem(approvePost, item)}
              disabled={updatingItem}
            >
              <ApproveIcon />
            </Button>
          </div>
        </td>
      </tr>
    );
  }

  tableComponent(data = {}) {
    const { content } = data;
    if (!content || content.length <= 0) return '';

    return (
      <div className={sharedStyles.table_wrapper}>
        <table className={classNames(styles.denied__table, sharedStyles.table)}>
          {this.headerComponent()}
          <tbody className={sharedStyles.table__body}>
            {content.map(this.rowComponent)}
          </tbody>
        </table>
      </div>
    );
  }

  updateItem(method, item) {
    let { updating } = this.state;

    if (updating.findIndex((x) => x.id === item.id) <= -1) {
      updating.push(item);
    }

    this.setState({ updating, modal: {} });

    method(item)
      .then(() => this.refetch())
      .catch((err) => {
        const modal = { kind: ModalKind.Error };

        const indexOf = updating.findIndex((x) => x.id === item.id);
        if (indexOf <= -1) return;

        updating.splice(indexOf, 1);
        this.setState({ updating, modal });
      });
  }

  render() {
    const { className } = this.props;
    const { modal } = this.state;

    const componentClasses = classNames(styles.denied, {
      [className]: className,
    });

    return (
      <AuthenticatedPage title="Denied" className={componentClasses}>
        {/* Post table ----------------------------------------------------- */}
        <PostsRequest
          postStatus={PostStatus.DENIED}
          validPlaceholder={({ empty } = {}) =>
            empty && StatusMessages.Placeholder
          }
          didUpdateRefetch={(refetch) => (this.refetch = refetch)}
        >
          {({ data }) => this.tableComponent(data)}
        </PostsRequest>

        {/* Delete Confirmation modal -------------------------------------- */}
        {modal.kind === ModalKind.DeleteConfirmation && (
          <Modal.Generic
            title="Delete Post"
            description={`Do you want to delete ${modal.data.title}? This action can't be undone.`}
            actions={[
              {
                title: 'Cancel',
                onClick: () => this.setState({ modal: {} }),
                kind: ButtonKind.Interrupt,
              },
              {
                title: 'Delete',
                onClick: () => this.updateItem(deletePost, modal.data),
                kind: ButtonKind.Info,
              },
            ]}
            onClose={() => this.setState({ modal: {} })}
          />
        )}

        {/* Error modal ---------------------------------------------------- */}
        {modal.kind === ModalKind.Error && (
          <Modal.Error onClose={() => this.setState({ modal: {} })} />
        )}
      </AuthenticatedPage>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Denied;
