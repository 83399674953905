//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import { FaLock as LockIcon } from 'react-icons/fa';
import { Link } from 'gatsby';
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Input, Button, Feedback, FeedbackKind } from '@cmp/common';
import { withQueryParams } from '@cmp/common/hoc';
//------------------------------------------------------------------------------
// Helpers, Classes & Constants ------------------------------------------------
import FormValidator from '@/classes/formValidator';
import { StatusMessages } from '@helpers/constants/api';
import { isPasswordValid } from '@helpers/validator';
import { validationFormatter } from '@helpers/formatter';
//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------
import { updatePassword } from '@api/endpoints/post';
//------------------------------------------------------------------------------
// Constants -------------------------------------------------------------------
export const UpdatePasswordKind = {
  ResetPassword: {
    action: 'Reset',
    message: (
      <>
        Your password was reset. <Link to="/">Click here</Link> to sign in.
      </>
    ),
  },
  ActivateAccount: {
    action: 'Activate Account',
    message: (
      <>
        Your account is now activated. <Link to="/">Click here</Link> to sign
        in.
      </>
    ),
  },
};
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class UpdatePassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      confirmPassword: '',
      loading: false,
      feedback: null,
    };
  }

  componentDidMount() {
    const items = [
      {
        key: 'password',
        defaultValue: '',
        method: isPasswordValid,
        validWhen: true,
        error: 'Your password must consist of 8 characters or more',
      },
      {
        key: 'confirmPassword',
        defaultValue: '',
        method: (confirmPassword, data) => confirmPassword === data.password,
        validWhen: true,
        error: 'Passwords must match',
      },
    ];

    this.validator = new FormValidator(items);
  }

  onSubmit(e) {
    const {
      kind,
      __searchParams: { token },
    } = this.props;
    const { password, confirmPassword } = this.state;

    if (e) e.preventDefault();

    const validateAll = this.validator.validateAll({
      password,
      confirmPassword,
    });

    const formattedValidation = validationFormatter(validateAll);

    this.setState({
      feedback: {
        title: 'Check your information',
        text: formattedValidation.list(),
        kind: FeedbackKind.Error,
      },
    });

    if (!validateAll.isValid) return;

    let that = this;
    let response = { loading: true, feedback: null };

    this.setState({ ...response });

    // Submit data
    updatePassword({ token, password })
      .then(() => {
        // To-do (P0) | API: response should not log the user in. If someone,
        // for some reason has access to the link, all they need to do is type
        // a new password. Ideally, they'd have to sign in, proving that they
        // at least know the user's email.
        response.feedback = {
          title: 'Success',
          text: kind.message,
        };

        response.password = '';
        response.confirmPassword = '';
      })
      .catch(() => {
        // To-do (P2): Check status code; ideally in a middleware?

        response.feedback = {
          title: StatusMessages.Error.Generic,
          text: <>Please refresh the page and try again.</>,
          kind: FeedbackKind.Error,
        };
      })
      .finally(() => {
        response.loading = false;
        that.setState({ ...response });
      });
  }

  render() {
    const { password, confirmPassword, loading, feedback } = this.state;
    const { kind, className } = this.props;

    const componentClasses = classNames(styles.retrieve, {
      [className]: className,
    });

    return (
      <form className={componentClasses} onSubmit={(e) => this.onSubmit(e)}>
        <Helmet>
          <title>Reset Your Password</title>
        </Helmet>

        <Input
          placeholder="Password"
          icon={LockIcon}
          type="password"
          onChange={(password) => this.setState({ password })}
          value={password}
        />
        <Input
          placeholder="Confirm Password"
          icon={LockIcon}
          type="password"
          onChange={(confirmPassword) => this.setState({ confirmPassword })}
          value={confirmPassword}
        />
        <Button
          className={styles.signIn__button}
          loading={loading}
          type="submit"
        >
          <span>{kind.action}</span>
        </Button>
        {feedback && <Feedback {...feedback} />}
      </form>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withQueryParams(
  [{ key: 'token', required: true }],
  UpdatePassword
);
