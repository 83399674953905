//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Modal } from '@cmp/common';
//------------------------------------------------------------------------------
// Constants -------------------------------------------------------------------
import { StatusMessages } from '@helpers/constants/api';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class ModalError extends React.Component {
  render() {
    const { onClose, ...rest } = this.props;

    return (
      <Modal.Generic
        {...rest}
        title={StatusMessages.Error.Generic}
        description="Please try again. If the problem persists, contact our support team."
        onClose={onClose}
      />
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default ModalError;
