//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
function withModal(WrappedComponent) {
  class WithModal extends React.Component {
    constructor(props) {
      super(props);
      this.previousLocation = this.props.location;
    }

    componentDidUpdate(prevProps) {
      const { location } = this.props;
      const { location: prevLocation } = prevProps;

      const isModal = location.state && location.state.modal;
      const isPrevModal = prevLocation.state && prevLocation.state.modal;

      if (location !== prevLocation && !isModal && !isPrevModal) {
        window.scrollTo(0, 0);
        document.body.scrollTo(0, 0);
      }

      if (!isPrevModal) {
        this.previousLocation = prevLocation;
      }
    }

    render() {
      const { location } = this.props;

      const isModal =
        location.state &&
        location.state.modal &&
        this.previousLocation !== location;

      const from =
        (isModal && location.state && location.state.from) || location;

      return <WrappedComponent isModal={isModal} from={from} {...this.props} />;
    }
  }

  WithModal.displayName = `WithModal(${getDisplayName(WrappedComponent)})`;

  return WithModal;
}
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withModal;
