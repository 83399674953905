//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { FaPlus as CreateIcon } from 'react-icons/fa';
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import Form from './pages/form';
import AccessToken from './pages/accessToken';
import SwitchAccount from './pages/switchAccount';
//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------
import { AdminUser as UserRequest } from '@api/endpoints/get';
//------------------------------------------------------------------------------
// Helpers & Constants ---------------------------------------------------------
//  Local ----------------------------------------------------------------------
export const PageMode = {
  CREATE: {
    title: 'Create user',
    action: {
      icon: <CreateIcon />,
      title: 'Create',
    },
    pages: [Form],
    requiresUser: false,
  },
  EDIT: {
    title: 'Edit user',
    action: {
      title: 'Update',
    },
    pages: [Form, SwitchAccount, AccessToken],
    requiresUser: true,
  },
};
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Create extends React.Component {
  pageMode() {
    const { id } = this.props;
    return id ? PageMode.EDIT : PageMode.CREATE;
  }

  render() {
    const { className, location: { state = {} } = {} } = this.props;

    const componentClasses = classNames(styles.edit, {
      [className]: className,
    });

    const pageMode = this.pageMode();
    const { user: cachedUser } = state || {};

    return (
      <div className={componentClasses}>
        {/* If there's something cached (from our state), force the request
            component to hide/disable the state indicator component. Otherwise,
            set it to undefined so it can be presented normally. */}
        <UserRequest
          id={cachedUser && cachedUser.id}
          skip={!cachedUser}
          stateIndicatorComponent={cachedUser ? null : undefined}
          didUpdateRefetch={(refetch) => (this.refetch = refetch)}
        >
          {({ data }) => {
            const { user } = data || {};

            const idealUser = user || cachedUser;
            if (!idealUser && pageMode.requiresUser) return '';

            return (
              <>
                {pageMode.pages.map((Page) => (
                  <Page
                    user={idealUser}
                    mode={pageMode}
                    key={Page}
                    onUpdate={() => this.refetch()}
                  />
                ))}
              </>
            );
          }}
        </UserRequest>
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Create;
