//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import {
  FaTrashAlt as TrashIcon,
  FaEdit as EditIcon,
  FaImages as ImagesIcon,
} from 'react-icons/fa';
import { Link } from 'gatsby';
//------------------------------------------------------------------------------
// Assets ----------------------------------------------------------------------
import { UserPlaceholderImage } from '@img/common';
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Button, ButtonKind, Image } from '@cmp/common';
import { Crest as CrestIcon } from '@cmp/common/icons';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import {
  userFormatter,
  nounFormatter,
  postFormatter,
} from '@helpers/formatter';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class PostGrid extends React.Component {
  constructor(props) {
    super(props);
    this.itemComponent = this.itemComponent.bind(this);
  }

  contentComponent(post) {
    const { title, user, createdAt } = post;
    const formattedPost = postFormatter(post);

    return (
      <div className={styles.item__content}>
        {/* ---------------------------------------------------------------- */}
        {/* User (Profile Picture + Name) ---------------------------------- */}
        <div className={styles.item__user}>
          <Image
            src={user.profileImageUrl}
            customPlaceholder={UserPlaceholderImage}
            className={styles.user__profile_image}
          />
          <div className={styles.item__information}>
            <span className={styles.item__creator}>
              {userFormatter(user).fullName()}
            </span>
            <span className={styles.item__date}>
              {postFormatter({ createdAt }).createdOn().formatted}
            </span>
          </div>
        </div>
        {/* ---------------------------------------------------------------- */}
        {/* Details (Title + Year + Description) --------------------------- */}
        <div className={styles.item__details}>
          <header className={styles.details__header}>
            <span className={styles.item__title}>{title}</span>
            {/* This intentionally removes years that are 0 */}
            {formattedPost.year ? (
              <span className={styles.item__year}>{formattedPost.year}</span>
            ) : (
              ''
            )}
          </header>
          <span className={styles.item__description}>
            {formattedPost.trimmedDescription}
          </span>
        </div>
      </div>
    );
  }

  footerComponent(post) {
    const { likes, loginUserLiked } = post;
    const {
      onLike,
      accessControl: { Post: AccessControlPost = {} } = {},
      onDelete,
      onEdit,
    } = this.props;

    return (
      <footer className={styles.item__footer}>
        <div className={styles.item__interactions}>
          <button
            className={styles.interactions__likes}
            onClick={() => onLike(post)}
          >
            <CrestIcon
              className={styles.interactions__icon}
              filled={loginUserLiked}
            />
            <span className={styles.interactions__text}>
              {nounFormatter.idealForm('Like', 'Likes', likes, true)}
            </span>
          </button>
        </div>
        <div className={styles.item__actions}>
          {/* To-do (P2): Add tooltip to buttons */}
          {AccessControlPost.DELETE && (
            <Button
              className={styles.actions__button}
              onClick={() => onDelete(post)}
            >
              <TrashIcon />
            </Button>
          )}
          {AccessControlPost.EDIT && (
            <Button
              className={styles.actions__button}
              kind={ButtonKind.Info}
              onClick={() => onEdit(post)}
            >
              <EditIcon />
            </Button>
          )}
        </div>
      </footer>
    );
  }

  itemComponent(post) {
    const { location } = this.props;

    return (
      <div className={styles.post_grid__item} key={post.id}>
        <Link
          className={styles.item__header}
          to={`/post/${post.id}`}
          state={{
            modal: true,
            from: location,
            post,
          }}
        >
          <Image className={styles.item__image} src={post.titleImageUrl} />
          {post.postImages && <ImagesIcon className={styles.item__gallery} />}
        </Link>
        {this.contentComponent(post)}
        {this.footerComponent(post)}
      </div>
    );
  }

  render() {
    const { className, posts } = this.props;
    if (!posts) return '';

    const componentClasses = classNames(styles.post_grid, {
      [className]: className,
    });

    return (
      <div className={componentClasses}>{posts.map(this.itemComponent)}</div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default PostGrid;
