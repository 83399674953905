//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from './index.scss';
import sharedStyles from '@styles/common/_shared.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { AuthenticatedPage } from '@cmp/authenticated';
//------------------------------------------------------------------------------
// Helpers & Constants ---------------------------------------------------------
import { userFormatter } from '@helpers/formatter';
//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------
import { AdminContributors as ContributorsRequest } from '@api/endpoints/get';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Dashboard extends React.Component {
  //----------------------------------------------------------------------------
  // Table (Top 15 list) -------------------------------------------------------
  tableComponent(data = {}) {
    const { topContributors = [] } = data;

    return (
      <div
        className={classNames(
          styles.dashboard__post_count,
          styles.dashboard__content
        )}
      >
        <h1 className={styles.dashboard__title}>Top 15 list</h1>
        <div className={sharedStyles.table_wrapper}>
          <table
            className={classNames(styles.post_count__table, sharedStyles.table)}
          >
            {this.headerComponent()}
            <tbody className={sharedStyles.table__body}>
              {topContributors.map((x) => this.rowComponent(x))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  headerComponent() {
    return (
      <thead className={sharedStyles.table__header}>
        <tr className={sharedStyles.header__row}>
          <th>User</th>
          <th>Dealer</th>
          <th>Posts</th>
        </tr>
      </thead>
    );
  }

  rowComponent(data) {
    const formattedUser = userFormatter(data);

    return (
      <tr className={sharedStyles.table__row} key={data.id}>
        <td className={sharedStyles.row__data}>{formattedUser.fullName()}</td>
        <td className={sharedStyles.row__data}>{data.organization}</td>
        <td className={sharedStyles.row__data}>{data.postCnt}</td>
      </tr>
    );
  }
  // ---------------------------------------------------------------------------
  // Aggregated ----------------------------------------------------------------
  aggregatedComponent(data = {}) {
    const {
      publishedPostCnt = '0',
      rejectedPostCnt = '0',
      totalPostCnt = '0',
      userCnt = '0',
    } = data;

    return (
      <div
        className={classNames(
          styles.dashboard__content,
          styles.dashboard__aggregated
        )}
      >
        <div className={styles.aggregated__item}>
          <h1 className={styles.dashboard__title}>Post count</h1>
          <div className={styles.aggregated__counts}>
            <span
              className={classNames(
                styles.aggregated__count,
                styles[`aggregated__count--added`]
              )}
            >
              {publishedPostCnt}
            </span>
            <span
              className={classNames(
                styles.aggregated__count,
                styles[`aggregated__count--removed`]
              )}
            >
              {rejectedPostCnt}
            </span>
            <span className={styles.aggregated__count}>{totalPostCnt}</span>
          </div>
        </div>
        <div className={styles.aggregated__item}>
          <h1 className={styles.dashboard__title}>User count</h1>
          <div className={styles.aggregated__counts}>
            <span className={styles.aggregated__count}>{userCnt}</span>
          </div>
        </div>
      </div>
    );
  }
  // ---------------------------------------------------------------------------
  // Render --------------------------------------------------------------------
  render() {
    const { className } = this.props;

    const componentClasses = classNames(styles.dashboard, {
      [className]: className,
    });

    return (
      <AuthenticatedPage
        title="Exclusive Gallery Dashboard"
        customPageTitle="Dashboard"
        className={componentClasses}
      >
        <ContributorsRequest top={15}>
          {({ data }) => (
            <>
              {this.tableComponent(data)}
              {this.aggregatedComponent(data)}
            </>
          )}
        </ContributorsRequest>
      </AuthenticatedPage>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Dashboard;
