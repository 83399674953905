//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { FaFileExport as ExportIcon } from 'react-icons/fa';
import Select from 'react-select';
import moment from 'moment';
import { Link } from 'gatsby';
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from './index.scss';
import sharedStyles from '@styles/common/_shared.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Button, ButtonKind } from '@cmp/common';
import { AuthenticatedPage } from '@cmp/authenticated';
//------------------------------------------------------------------------------
// Helpers & Constants ---------------------------------------------------------
import { flatSelectOptions, orderedSelectOptions } from '@helpers/select';
import {
  PorscheSelectTheme,
  PorscheSelectStyle,
} from '@helpers/constants/select';
import { AllMonths, Years } from '@helpers/constants/date';
import { StatusMessages, FormattedDate } from '@helpers/constants/api';
//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------
import {
  AdminPostViews as PostViewsRequest,
  exportPostViews,
} from '@api/endpoints/get';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class TopPosts extends React.Component {
  // This is very similar to TopContributors. Find a way to implement this as a
  // generic compnoent that can be used for both.

  constructor(props) {
    super(props);

    this.state = {
      year: undefined,
      month: undefined,
    };

    // Setting this here to avoid having to make declare these again in
    // componentDidMount (in order to find the default value, the current date).
    this.yearsOptions = flatSelectOptions(Years());
    this.monthsOptions = orderedSelectOptions(AllMonths());

    this.rowComponent = this.rowComponent.bind(this);
  }

  componentDidMount() {
    const currentYear = moment().year().toString();
    const currentMonth = moment().month();

    this.setState({
      year: this.yearsOptions.find(({ value }) => value === currentYear),
      month: this.monthsOptions.find(({ value }) => value === currentMonth),
    });
  }

  rowComponent(data) {
    const { location } = this.props;

    return (
      <tr className={sharedStyles.table__row} key={data.id}>
        <td className={sharedStyles.row__data}>
          {data.userFirstName} {data.userLastName}
        </td>
        <td className={sharedStyles.row__data}>
          <Link
            to={`/post/${data.id}`}
            state={{
              modal: true,
              from: location,
              post: data,
            }}
          >
            {data.title}
          </Link>
        </td>
        <td className={sharedStyles.row__data}>{data.viewCnt}</td>
      </tr>
    );
  }

  tableComponent(data = []) {
    return (
      <div className={sharedStyles.table_wrapper}>
        <table
          className={classNames(styles.top_posts__table, sharedStyles.table)}
        >
          <thead className={sharedStyles.table__header}>
            <tr className={sharedStyles.header__row}>
              <th>Creator</th>
              <th>Post Title</th>
              <th># Hits in month</th>
            </tr>
          </thead>
          <tbody className={sharedStyles.table__body}>
            {data.map(this.rowComponent)}
          </tbody>
        </table>
      </div>
    );
  }

  headerComponent() {
    const { month = {}, year = {} } = this.state;
    const formattedDate = FormattedDate(month.value, year.value);

    return (
      <div className={styles.top_posts__header}>
        <div className={styles.header__filter}>
          <Select
            className={styles.filter__select}
            options={this.monthsOptions}
            value={month}
            onChange={(month) => this.setState({ month }, () => this.refetch())}
            theme={PorscheSelectTheme}
            styles={PorscheSelectStyle}
          />
          <Select
            className={styles.filter__select}
            options={this.yearsOptions}
            value={year}
            onChange={(year) => this.setState({ year }, () => this.refetch())}
            theme={PorscheSelectTheme}
            styles={PorscheSelectStyle}
          />
        </div>
        <Button
          kind={ButtonKind.Interrupt}
          onClick={() => exportPostViews(formattedDate)}
        >
          <ExportIcon />
          <span>Export (CSV)</span>
        </Button>
      </div>
    );
  }

  render() {
    const { className } = this.props;
    const { month = {}, year = {} } = this.state;
    const formattedDate = FormattedDate(month.value, year.value);

    const componentClasses = classNames(styles.top_posts, {
      [className]: className,
    });

    return (
      <AuthenticatedPage title="Top Posts" className={componentClasses}>
        {this.headerComponent()}

        <PostViewsRequest
          params={{
            // If needed, a limit can be added here.
            // limit: 5,
            date: formattedDate,
          }}
          skip={!formattedDate}
          validPlaceholder={(data = {}) =>
            (!data || data.length <= 0) && StatusMessages.Placeholder
          }
          didUpdateRefetch={(refetch) => (this.refetch = refetch)}
        >
          {({ data }) => this.tableComponent(data)}
        </PostViewsRequest>
      </AuthenticatedPage>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default TopPosts;
