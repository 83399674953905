import { defaultClient } from '@api/client';

export const updateUser = (id, payload) => {
  return defaultClient.put(`/admin/users/${id}`, payload);
};

export const approveUser = (id) => {
  return defaultClient.put(`/admin/users/${id}/approve`);
};

export const denyUser = (id, reason) => {
  return defaultClient.put(`/admin/users/${id}/deny`, { reason });
};
