let observers = [];

const isValidEnvironment = () => {
  return typeof localStorage !== 'undefined' && localStorage;
};

export const storeValue = (key, value) => {
  if (!isValidEnvironment()) return false;

  notifyObservers(key, value);
  localStorage.setItem(key, value);
};

export const removeAll = () => {
  // We should notify all observers that their objects watched are being
  // removed, but there's no actual use case for this.

  if (!isValidEnvironment()) return false;
  localStorage.clear();
};

export const removeItem = (key) => {
  if (!isValidEnvironment()) return false;

  notifyObservers(key, null);
  localStorage.removeItem(key);
};

export const getValue = (key, parsing) => {
  if (!isValidEnvironment()) return false;

  const localItem = localStorage.getItem(key);
  if (!localItem) return;

  return parsing ? JSON.parse(localItem) : localItem;
};

export const addObserver = (key, callback) => {
  observers.push({ key, callback });
};

export const removeObserver = (key, callback) => {
  const indexOf = observers.findIndex((observer) => observer.key === key && observer.callback === callback);
  if (indexOf <= -1) return;

  observers.splice(indexOf);
};

const notifyObservers = (key, value) => {
  const filteredObservers = observers.filter((observer) => observer.key === key);
  filteredObservers.forEach((observer) => {
    if (observer.callback) {
      observer.callback(value);
    }
  });
};
