//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import {
  FaCheckCircle as SuccessIcon,
  FaInfoCircle as InfoIcon,
  FaExclamationCircle as ErrorIcon,
  FaExclamationTriangle as WarningIcon,
} from 'react-icons/fa';
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------

export const FeedbackKind = {
  Success: {
    classModifier: 'success',
    icon: SuccessIcon,
  },
  Warning: {
    classModifier: 'warning',
    icon: WarningIcon,
  },
  Error: {
    classModifier: 'error',
    icon: ErrorIcon,
  },
  Info: {
    classModifier: 'info',
    icon: InfoIcon,
  },
};

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Feedback extends React.Component {
  render() {
    const { className, text, title, kind = FeedbackKind.Success } = this.props;
    const IconComponent = kind.icon;

    const componentClasses = classNames(styles.feedback, {
      [styles[`feedback--${kind.classModifier}`]]: kind,
      [className]: className,
    });

    return (
      <div className={componentClasses}>
        <IconComponent className={styles.feedback__icon} />
        <div className={styles.feedback__content}>
          {title && <span className={styles.feedback__title}>{title}</span>}
          {text && <span className={styles.feedback__text}>{text}</span>}
        </div>
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Feedback;
