//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
//------------------------------------------------------------------------------
// Constants -------------------------------------------------------------------
const ScrollingOffset = 1200;
//------------------------------------------------------------------------------
// HOC -------------------------------------------------------------------------
function withInfiniteScrolling(WrappedComponent) {
  class WithInfiniteScrolling extends React.Component {
    constructor(props) {
      super(props);
      this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
      document.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
      document.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll(e) {
      if (this.shouldAnalyzeScrolling && !this.shouldAnalyzeScrolling()) return;

      const {
        target: { scrollingElement },
      } = e;

      const scrollingBottom =
        scrollingElement.scrollTop + scrollingElement.offsetHeight;
      const { scrollHeight } = scrollingElement;

      if (scrollHeight <= scrollingBottom + ScrollingOffset) {
        if (this.hasScrolled) {
          this.hasScrolled();
        }
      }
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          __infiniteScrolling={{
            shouldAnalyzeScrolling: (shouldAnalyzeScrolling) =>
              (this.shouldAnalyzeScrolling = shouldAnalyzeScrolling),
            hasScrolled: (hasScrolled) => (this.hasScrolled = hasScrolled),
          }}
        />
      );
    }
  }

  WithInfiniteScrolling.displayName = `WithInfiniteScrolling(${getDisplayName(
    WrappedComponent
  )})`;

  return WithInfiniteScrolling;
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default withInfiniteScrolling;
