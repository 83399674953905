import { UserRoles } from '@helpers/constants/user';

export const AccessControlList = (user) => {
  // We should revisit this later on. I don't really like this implementation
  // for a few reasons...
  // We're unnecessarily calling indexOf multiple times on render in Home; the
  // current object being returned is not flexible enough (what if a user can
  // edit their own posts?); it doesn't integrate with routes.js.

  const { roles = [] } = user || {};

  const isAdmin = roles.indexOf(UserRoles.ADMIN) > -1;
  const isSuperAdmin = roles.indexOf(UserRoles.SUPER_ADMIN) > -1;

  return {
    Post: {
      EDIT: isAdmin || isSuperAdmin,
      DELETE: isAdmin || isSuperAdmin,
    },
  };
};
