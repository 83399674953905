//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class PageControl extends React.Component {
  allItems() {
    const { current, total } = this.props;
    let items = [];

    for (let i = 1; i <= total; i++) {
      let itemClasses = classNames(styles.page_control__item, {
        [styles[`page_control__item--selected`]]: i === current + 1,
      });

      items.push(<div className={itemClasses} key={i}></div>);
    }

    return items;
  }

  render() {
    const { className } = this.props;

    const componentClasses = classNames(styles.page_control, {
      [className]: className,
    });

    return <div className={componentClasses}>{this.allItems()}</div>;
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default PageControl;
