export const ImageTypes = [
  // Used to isolate the accepted file types
  { extension: '.jpg', type: 'image/jpeg', formatted: 'JPG' },
  { extension: '.jpeg', type: 'image/jpeg', formatted: 'JPG' },
  { extension: '.png', type: 'image/png', formatted: 'PNG' },
];

export const ImageTypesFormatted = () => {
  const formattedTypes = ImageTypes.map((x) => x.formatted);
  const filteredTypes = [...new Set(formattedTypes)];

  const allExceptLastItem = filteredTypes.slice(0, filteredTypes.length - 1);
  const lastItem = filteredTypes[filteredTypes.length - 1];

  return `${allExceptLastItem.join(', ')} or ${lastItem}`;
};

export const ImageTypeLimit = 5 * 100 * 100 * 100;
