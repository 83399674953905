//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { navigate } from 'gatsby';
import { Helmet } from 'react-helmet';
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Button, ButtonKind } from '@cmp/common';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class AuthenticatedPage extends React.Component {
  render() {
    const {
      className,
      children,
      title,
      description,
      actions,
      auxiliarComponent,
      customPageTitle,
    } = this.props;

    const componentClasses = classNames(styles.authenticated_page__content, {
      [className]: className,
    });

    return (
      <div className={styles.authenticated_page}>
        <Helmet>
          <title>{customPageTitle || title}</title>
        </Helmet>

        <header className={styles.authenticated_page__header}>
          <div className={styles.header__info}>
            {(title || description) && (
              <div className={styles.header__headline}>
                {title && <h1 className={styles.headline__title}>{title}</h1>}
                {description && (
                  <span className={styles.headline__description}>
                    {description}
                  </span>
                )}
              </div>
            )}
            {actions && (
              <div className={styles.header__actions}>
                {actions.map(
                  ({ label, icon: IconComponent, onClick, to, disabled }) => (
                    <Button
                      key={label}
                      onClick={() => (onClick && onClick()) || navigate(to)}
                      kind={ButtonKind.Interrupt}
                      disabled={disabled}
                    >
                      {IconComponent && <IconComponent />}
                      <p>{label}</p>
                    </Button>
                  )
                )}
              </div>
            )}
          </div>
          <div className={styles.header__auxiliar}>{auxiliarComponent}</div>
        </header>
        <div className={componentClasses}>{children}</div>
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default AuthenticatedPage;
