//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import axios from 'axios';
//------------------------------------------------------------------------------
// Interceptors ----------------------------------------------------------------
import {
  useAuthorizationInterceptor,
  useUnauthorizedInterceptor,
} from '@api/interceptors';
//------------------------------------------------------------------------------
// Client ----------------------------------------------------------------------
axios.defaults.baseURL = process.env.API_ENDPOINT;
axios.defaults.withCredentials = true;
useAuthorizationInterceptor(axios);
useUnauthorizedInterceptor(axios);
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default axios;
