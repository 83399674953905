// 🚗

import moment from 'moment';

export const ModelLine = {
  911: '911',
  718: '718',
  cayenne: 'Cayenne',
  macan: 'Macan',
  panamera: 'Panamera',
  taycan: 'Taycan',
};

export const ColorTone = {
  WHITE: 'White',
  BLACK: 'Black',
  RED: 'Red',
  BLUE: 'Blue',
  GREEN: 'Green',
  YELLOW: 'Yellow',
  SILVER: 'Silver',
  BROWN: 'Brown',
  GREY: 'Grey',
};

export const Transmission = {
  MANUAL: 'Manual',
  PDK: 'PDK',
  TRIPTRONIC: 'Triptronic',
};

const ModelYearLowerBound = '2005';
const ModelYearFormat = 'Y';

export const ModelYear = () => {
  // Start with an empty array to store  all year options
  let years = [];

  // Create a date and add one year, always assuming the existence of a newer
  // model
  const date = moment();
  date.add(1, 'year');

  // Format the date (“2020”, “2021”...)
  let formattedYear = date.format(ModelYearFormat);

  // If the formatted year is different than our lower bound (>2005)
  while (formattedYear !== ModelYearLowerBound) {
    // Add the current formatted year as an option
    years.push({ label: formattedYear, value: formattedYear });

    // And subtract one year from the date, eventually reaching our lower bound
    date.subtract(1, 'year');
    formattedYear = date.format(ModelYearFormat);
  }

  return years;
};
