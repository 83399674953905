//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import { Redirect } from '@reach/router';
//------------------------------------------------------------------------------
// My Component ----------------------------------------------------------------
import { withAuthUser } from '@cmp/common/hoc';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class PrivateRoute extends React.Component {
  render() {
    const {
      __authUser,
      redirectTo,
      component: Component,
      publicComponent: PublicComponent,
      ...rest
    } = this.props;
    const hasAuthUser = __authUser && Object.keys(__authUser).length > 0;

    if (hasAuthUser) {
      return <Component {...rest} />;
    }

    if (PublicComponent) {
      return <PublicComponent {...rest} />;
    }

    return <Redirect from="*" to={redirectTo || '/'} />;
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withAuthUser(PrivateRoute);
