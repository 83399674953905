//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { AuthenticatedPage } from '@cmp/authenticated';
import { Button, ButtonKind } from '@cmp/common';
//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------
import { regenerateAccessToken as regenerateAccessTokenRequest } from '@api/endpoints/get';
//------------------------------------------------------------------------------
// Helpers & Constants ---------------------------------------------------------
import { StatusMessages } from '@helpers/constants/api';
import { BaseUrl } from '@helpers/url';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class SwitchAccount extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: null,
    };
  }

  redirectToUser(accessToken) {
    let params = new URLSearchParams();
    params.append('token', accessToken);

    const url = `${BaseUrl()}?${params.toString()}`;

    window.location = url;
  }

  async onSwitchClick() {
    const { user = {} } = this.props;
    if (!user) return;

    const { accessToken } = user;

    // When clicking the button to switch users, the first thing we do is check
    // if there is an existing access token associated with them.
    // If there is, the process is as simple as redirecting to the base URL with
    // the token as a URL parameter.

    if (accessToken) {
      this.redirectToUser(accessToken);
    } else {
      // If there isn't an access token available yet, generate it by using the
      // `regenerateAccessToken` method.
      // First, update the state so the user knows it's loading. Also reset the
      // error in case they've tried generating one time and it failed.

      this.setState({ loading: true, error: null });

      let updatedState = {
        accessToken: null,
        error: null,
      };

      try {
        // Then call `regenerateAccessToken`, get and analyse its data and
        // redirect to the base URL as expected.

        let { data } = await regenerateAccessTokenRequest(user);
        if (!data || !data.accessToken) throw new Error('Invalid data');
        this.redirectToUser(data.accessToken);
      } catch (e) {
        // If something goes wrong, update the state with the generic error
        // message.
        updatedState.error = StatusMessages.Error.Actionable;
      } finally {
        // Finally (a successful run won't get here) remove the loading
        // indicator and update the state.
        updatedState.loading = false;
        this.setState(updatedState);
      }
    }
  }

  render() {
    const { user = {} } = this.props;
    const { error, loading } = this.state;

    return (
      <AuthenticatedPage title="Switch Account" className={styles.edit__switch}>
        <Button
          kind={ButtonKind.Info}
          onClick={() => this.onSwitchClick()}
          disabled={!user}
          loading={loading}
        >
          Switch
        </Button>
        {error && (
          <p
            className={classNames(styles.switch__message, styles.switch__error)}
          >
            {error}
          </p>
        )}
      </AuthenticatedPage>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default SwitchAccount;
