//------------------------------------------------------------------------------
// hoc/mountClient -------------------------------------------------------------
// Gatsby first serves the static version of the page, then to show what was
// rehydrated. The problem is this static version is the welcome page (what's
// available during the build).
// Certain logged in users might see, for less than a second, the authenticated
// page flashing. This high-order component fixes it, intentionally making the
// WrappedComponent null until we know for sure we're running as a client.
// There are some drawbacks from this solution, though. It definitely impacts
// the performance of first-load, but only for a few milliseconds.
//
// More info:
// https://www.gatsbyjs.com/docs/ssr-apis
// https://joshwcomeau.com/react/the-perils-of-rehydration/#rehydration--render
// https://github.com/gatsbyjs/gatsby/issues/25729
// https://github.com/gatsbyjs/gatsby/issues/17914
//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
//------------------------------------------------------------------------------
// HOC -------------------------------------------------------------------------
function mountClient(WrappedComponent) {
  class MountClient extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        isClient: false,
      };
    }

    componentDidMount() {
      this.setState({ isClient: true });
    }

    render() {
      const { isClient } = this.state;
      if (!isClient) return '';

      return (
        <WrappedComponent
          {...this.props}
          __keyboardEvents={{
            onKeyPress: (onKeyPress) => (this.onKeyPress = onKeyPress),
          }}
        />
      );
    }
  }

  MountClient.displayName = `MountClient(${getDisplayName(WrappedComponent)})`;

  return MountClient;
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default mountClient;
