export const exitFullScreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  }
};

export const requestFullScreen = (current) => {
  if (current.requestFullscreen) {
    current.requestFullscreen();
  } else if (current.mozRequestFullScreen) {
    current.mozRequestFullScreen();
  } else if (current.webkitRequestFullScreen) {
    current.webkitRequestFullScreen();
  } else if (current.msRequestFullscreen) {
    current.msRequestFullscreen();
  }
};
