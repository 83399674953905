//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import GET from '@api/methods/get';
//------------------------------------------------------------------------------
// Endpoint --------------------------------------------------------------------
const Request = {
  endpoint: `/admin/users`,
};
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
export const AdminUsers = ({
  children,
  searchQuery,
  page,
  status,
  params,
  ...props
}) => {
  const requestParams = {
    status,
    page,
    ...params,
  };

  if (searchQuery && searchQuery.length > 0) {
    requestParams.search = searchQuery;
  }

  return (
    <GET request={Request} params={requestParams} {...props}>
      {children}
    </GET>
  );
};
