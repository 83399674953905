//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import {
  FaPen as EditIcon,
  FaTrashAlt as DeleteIcon,
  FaUserPlus as CreateUserIcon,
  FaMailBulk as MailIcon,
  FaFileExport as ExportIcon,
} from 'react-icons/fa';
import { navigate } from 'gatsby';
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from './index.scss';
import sharedStyles from '@styles/common/_shared.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Button, ButtonKind, Modal, Pagination } from '@cmp/common';
import { AuthenticatedPage, SearchBar } from '@cmp/authenticated';
import { withAuthUser } from '@cmp/common/hoc';
//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------
import { AdminUsers as UsersRequest, exportUsers } from '@api/endpoints/get';
import { deleteUser as deleteUserRequest } from '@api/endpoints/delete';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { userFormatter } from '@helpers/formatter';
//------------------------------------------------------------------------------
// Constants -------------------------------------------------------------------
//  External -------------------------------------------------------------------
import { Status as UserStatus } from '@helpers/constants/user';
import { StatusMessages } from '@helpers/constants/api';
//  Local ----------------------------------------------------------------------
const ModalKind = {
  DeleteConfirmation: 'CONFIRMATION',
  Error: 'ERROR',
};
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class All extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchQuery: '',
      users: null,
      currentPage: 1,
      modal: {
        kind: null,
        data: null,
        loading: false,
      },
    };

    this.rowComponent = this.rowComponent.bind(this);
    this.onMailAllClick = this.onMailAllClick.bind(this);
  }

  headerComponent() {
    return (
      <thead className={sharedStyles.table__header}>
        <tr className={sharedStyles.header__row}>
          <th>Full name</th>
          <th>Role</th>
          <th>Email</th>
          <th>Organization</th>
          <th aria-label="Actions"></th>
        </tr>
      </thead>
    );
  }

  rowComponent(user) {
    const formattedUser = userFormatter(user);

    return (
      <tr className={sharedStyles.table__row} key={user.id}>
        <td className={sharedStyles.row__data}>{formattedUser.fullName()}</td>
        <td className={sharedStyles.row__data}>
          {formattedUser.roles().map((x) => (
            <span key={x} className={styles.row__role}>
              {x}
            </span>
          ))}
        </td>
        <td className={sharedStyles.row__data}>
          <a href={`mailto:${user.email}`}>{user.email}</a>
        </td>
        <td className={sharedStyles.row__data}>{user.organization}</td>
        <td className={sharedStyles.row__data}>
          <div className={sharedStyles.row__actions}>
            <Button
              kind={ButtonKind.Info}
              onClick={() =>
                navigate(`/users/${user.id}/edit`, { state: { user } })
              }
            >
              <EditIcon />
            </Button>
            <Button
              kind={ButtonKind.Primary}
              onClick={() =>
                this.setState({
                  modal: { kind: ModalKind.DeleteConfirmation, data: user },
                })
              }
            >
              <DeleteIcon />
            </Button>
          </div>
        </td>
      </tr>
    );
  }

  tableComponent(data) {
    const { content } = data || {};
    if (!content || content.length <= 0) return '';

    return (
      <div className={sharedStyles.table_wrapper}>
        <table className={classNames(styles.all__table, sharedStyles.table)}>
          {this.headerComponent()}
          <tbody className={sharedStyles.table__body}>
            {content.map(this.rowComponent)}
          </tbody>
        </table>
      </div>
    );
  }

  searchComponent() {
    const { searchQuery } = this.state;

    return (
      <div>
        <SearchBar
          className={styles.all__search_bar}
          placeholder="Search..."
          value={searchQuery}
          onChange={(searchQuery) => this.setState({ searchQuery })}
          onSearch={() =>
            this.setState({ currentPage: 1 }, () => this.refetch())
          }
          onClear={() =>
            this.setState({ currentPage: 1, searchQuery: '' }, () =>
              this.refetch()
            )
          }
        />
      </div>
    );
  }

  onMailAllClick() {
    const { __authUser: { user: loggedUser } = {} } = this.props;
    if (!loggedUser) return;

    const { users } = this.state;
    if (!users || users.length <= 0) return;

    window.location = `mailto:${loggedUser.email}?bcc=${users
      .map((x) => x.email)
      .join(',')}`;
  }

  deleteUser(user) {
    let { modal } = this.state;

    // Before deleting the user, we must disable the “cancel” and “confirm”
    // buttons.
    modal.loading = true;
    this.setState({ modal });

    // Make the DELETE request; depending on the response, either close the
    // modal (by setting the state object to an empty object), or update the
    // modal to the error kind.
    deleteUserRequest(user)
      .then(() => {
        modal = {};
        this.refetch();
      })
      .catch((err) => {
        modal = { kind: ModalKind.Error };
      })
      .finally(() => {
        this.setState({ modal });
      });
  }

  render() {
    const { className } = this.props;
    const { users, modal, currentPage, searchQuery } = this.state;

    const componentClasses = classNames(styles.all, {
      [className]: className,
    });

    return (
      <AuthenticatedPage
        title="Users"
        actions={[
          { label: 'New User', icon: CreateUserIcon, to: '/users/create' },
          {
            label: 'Mail to all',
            icon: MailIcon,
            onClick: this.onMailAllClick,
            disabled: !users || users.length <= 0,
          },
          { label: 'Export user list', icon: ExportIcon, onClick: exportUsers },
        ]}
        auxiliarComponent={this.searchComponent()}
        className={componentClasses}
      >
        {/* User table ----------------------------------------------------- */}
        <UsersRequest
          onComplete={({ content }) => this.setState({ users: content })}
          status={UserStatus.ACTIVE}
          searchQuery={searchQuery}
          page={currentPage}
          validPlaceholder={({ empty = true } = {}) =>
            empty && StatusMessages.Placeholder
          }
          didUpdateRefetch={(refetch) => (this.refetch = refetch)}
        >
          {({ data = {} }) => {
            const { first, last, totalPages } = data;

            return (
              <div className={styles.all__content}>
                {this.tableComponent(data)}
                <Pagination
                  className={styles.all__pagination}
                  first={first}
                  last={last}
                  total={totalPages}
                  current={currentPage}
                  onChange={(currentPage) =>
                    this.setState({ currentPage }, () => this.refetch())
                  }
                />
              </div>
            );
          }}
        </UsersRequest>

        {/* Delete Confirmation modal -------------------------------------- */}
        {modal.kind === ModalKind.DeleteConfirmation && (
          <Modal.Generic
            title="Delete User"
            description={`Do you want to delete ${modal.data.firstName}? This action can't be undone.`}
            actions={[
              {
                title: 'Cancel',
                onClick: () => this.setState({ modal: {} }),
                kind: ButtonKind.Interrupt,
                disabled: modal.loading,
              },
              {
                title: 'Delete',
                onClick: () => this.deleteUser(modal.data),
                kind: ButtonKind.Info,
                loading: modal.loading,
              },
            ]}
            onClose={() => this.setState({ modal: {} })}
          />
        )}

        {/* Error modal ---------------------------------------------------- */}
        {modal.kind === ModalKind.Error && (
          <Modal.Error onClose={() => this.setState({ modal: {} })} />
        )}
      </AuthenticatedPage>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withAuthUser(All);
