//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
//------------------------------------------------------------------------------
// Helpers & Constants ---------------------------------------------------------
import { getValue, removeAll, storeValue } from '@helpers/localStorage';
import { UserStorageKey } from '@constants/localStorage';
//------------------------------------------------------------------------------
// Exports ---------------------------------------------------------------------
export const withAuthUser = (WrappedComponent) => ({ children, ...props }) => (
  <WrappedComponent {...props} __authUser={getValue(UserStorageKey, true)}>
    {children}
  </WrappedComponent>
);

export const auth = (params = {}, skipRedirect) => {
  if (Object.keys(params).length > 0) {
    storeValue(UserStorageKey, JSON.stringify(params));
  } else {
    removeAll();
  }

  if (!skipRedirect) {
    window.location = '/';
  }
};

export const updateUser = (updatedUser) => {
  let storedUser = getValue(UserStorageKey, true);
  if (!storedUser) return;

  storedUser.user = updatedUser;

  storeValue(UserStorageKey, JSON.stringify(storedUser));
};

export const updateToken = (token) => {
  // This will, for a brief moment, make the token and the user out of sync. We
  // must always load the logged user and update it right away.
  let storedUser = getValue(UserStorageKey, true) || {};

  storedUser.jwtToken = token;
  storeValue(UserStorageKey, JSON.stringify(storedUser));
};

export const deauth = () => {
  removeAll();
  window.location = '/';
};

export const shouldUpdate = (token) => {
  if (!token) return false;

  const storedUser = getValue(UserStorageKey, true);
  if (!storedUser || !storedUser.jwtToken) return true;

  return storedUser.jwtToken !== token;
};
