export default class FormValidator {
  constructor(items) {
    this.items = items;
  }

  validateAll(data) {
    if (!this.items || this.items.length <= 0) return true;

    let invalid = [];

    for (let i = 0; i < this.items.length; i++) {
      let validationItem = this.items[i];
      let dataItem = data[validationItem.key];

      const valid = this.validateItem(validationItem, dataItem, data);

      if (valid !== validationItem.validWhen) {
        invalid.push(validationItem);
      }
    }

    if (invalid.length > 0) {
      return { isValid: false, invalidItems: invalid };
    } else {
      return { isValid: true };
    }
  }

  validateItem(validationItem, dataItem, data) {
    const valid = validationItem.method(
      dataItem || validationItem.defaultValue,
      data
    );
    return valid;
  }
}
