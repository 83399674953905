//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import GET from '@api/methods/get';
//------------------------------------------------------------------------------
// Endpoint --------------------------------------------------------------------
const Request = {
  endpoint: `/admin/contributors`,
};
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
export const AdminContributors = ({ children, top, params, ...props }) => {
  const requestParams = {
    ...params,
    top,
  };

  return (
    <GET request={Request} params={requestParams} {...props}>
      {children}
    </GET>
  );
};
