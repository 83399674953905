import { defaultClient } from '@api/client';

const exportRequest = (endpoint, extension) => {
  defaultClient.get(endpoint, { responseType: 'blob' }).then((res) => {
    const { data, headers } = res;

    // Create the URL from the blob
    const url = window.URL.createObjectURL(data);

    // Get the filename from the Content-Disposition header
    //  Set the default filename
    let filename = `export.${extension}`;

    //  Get the `Content-Disposition` header -- headers are case insensitive and
    //  axios lowercase them intentionally.
    const contentDisposition = headers[`content-disposition`];

    //  If the header is there
    if (contentDisposition) {
      // Split the header on the `;` character
      const contentSplit = contentDisposition.split('; ');

      // If there are more than one item
      if (contentSplit && contentSplit.length > 0) {
        // Update the content list so that the items are 0: key, 1: value
        const contentMapped = contentSplit.map((x) => x.split('='));

        // Search for the filename key in the mapped array
        const foundFilename = contentMapped.find((x) => x[0] === 'filename');

        // Make sure there is a filename available
        if (foundFilename && foundFilename.length > 1) {
          filename = foundFilename[1];
        }
      }
    }

    // Create a link with the correct href and attributes (so that the file
    // downloads)
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);

    // Append it to the body
    document.body.appendChild(link);

    // And simulate a click
    link.click();
  });
};

export const exportUsers = () => {
  exportRequest('/admin/export/users', 'xlsx');
};

export const exportContributors = (date = '') => {
  exportRequest(`/admin/export/top-contributors?date=${date}`, 'csv');
};

export const exportPostViews = (date = '') => {
  exportRequest(`/admin/export/top-post-views?date=${date}`, 'csv');
};
