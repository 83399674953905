//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import GET from '@api/methods/get';
//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------
import { defaultClient } from '@api/client';
//------------------------------------------------------------------------------
// Endpoint --------------------------------------------------------------------
const Request = (id) => ({
  endpoint: `/users/${id || 'self'}`,
});
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
export const User = ({ children, id, ...props }) => {
  return (
    <GET request={Request(id)} {...props}>
      {children}
    </GET>
  );
};

export const loadUser = (id) => {
  return defaultClient.get(Request(id).endpoint);
};
