// To-do (P3): This should be mapped to a “readable” variable in a more decent
// way eventually.
export const UserRoles = {
  SUPER_ADMIN: 'ROLE_SUPER_ADMIN',
  ADMIN: 'ROLE_ADMIN',
  PUBLISHER: 'ROLE_PUBLISHER',
  USER: 'ROLE_DEALER',
};

export const ReadableUserRoles = {
  ROLE_ADMIN: 'Administrator',
  ROLE_PUBLISHER: 'Publisher',
  ROLE_DEALER: 'User',
};

// We're not using or setting user organizations anymore, but it should be fine
// to leave this constant around.
export const Organizations = {
  PCNA: 'PCNA',
};

export const Status = {
  ACTIVE: 'ACTIVE',
  PENDING: 'PENDING',
};
