//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import { FaUserAlt as UserIcon } from 'react-icons/fa';
import { isEmail } from 'validator';
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Input, Button, Feedback, FeedbackKind } from '@cmp/common';
//------------------------------------------------------------------------------
// Helpers, Classes & Constants ------------------------------------------------
import FormValidator from '@/classes/formValidator';
import { StatusMessages } from '@helpers/constants/api';
import { validationFormatter } from '@helpers/formatter';
//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------
import { forgotPassword } from '@api/endpoints/post';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      loading: false,
      feedback: null,
    };
  }

  componentDidMount() {
    const items = [
      {
        key: 'email',
        defaultValue: '',
        method: isEmail,
        validWhen: true,
        error: 'Enter a valid email',
      },
    ];

    this.validator = new FormValidator(items);

    const {
      location: { state },
    } = this.props;
    this.setState({ email: (state && state.email) || '' });
  }

  onSubmit(e) {
    const { email } = this.state;

    if (e) e.preventDefault();

    const validateAll = this.validator.validateAll({ email });
    const formattedValidation = validationFormatter(validateAll);

    this.setState({
      feedback: {
        title: 'Check your information',
        text: formattedValidation.list(),
        kind: FeedbackKind.Error,
      },
    });

    if (!validateAll.isValid) return;

    // Update the state so the button shows a spinner and becomes unavailable
    let that = this;
    let response = { loading: true, feedback: null };

    this.setState({ ...response });

    // Submit data
    forgotPassword({ email })
      .then(() => {
        response.email = '';

        response.feedback = {
          title: 'Success',
          text:
            "We've just sent you an email with details on how to reset your password.",
        };
      })
      .catch(() => {
        // To-do (P2): Check status code; ideally in a middleware?
        response.feedback = {
          title: StatusMessages.Error.Generic,
          text: 'Please make sure your email is correct and try again.',
          kind: FeedbackKind.Error,
        };
      })
      .finally(() => {
        response.loading = false;
        that.setState({ ...response });
      });
  }

  render() {
    const { email, loading, feedback } = this.state;
    const { className } = this.props;

    const componentClasses = classNames(styles.forgotPassword, {
      [className]: className,
    });

    return (
      <form className={componentClasses} onSubmit={(e) => this.onSubmit(e)}>
        <Helmet>
          <title>Forgot Password</title>
        </Helmet>

        <Input
          placeholder="Email"
          type="email"
          icon={UserIcon}
          onChange={(email) => this.setState({ email })}
          value={email}
        />
        <Button
          className={styles.signIn__button}
          loading={loading}
          type="submit"
        >
          <span>Send confirmation email</span>
        </Button>
        {feedback && <Feedback {...feedback} />}
      </form>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default ForgotPassword;
