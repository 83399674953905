//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { FaCheck as CheckIcon } from 'react-icons/fa';
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Checkbox extends React.Component {
  render() {
    const { className, selected, onChange } = this.props;

    const componentClasses = classNames(styles.checkbox, {
      [styles['checkbox--selected']]: selected,
      [className]: className,
    });

    const clickCallback = () => onChange(!selected);

    return (
      <div
        className={componentClasses}
        role="button"
        tabIndex={-1}
        onKeyDown={clickCallback}
        onClick={clickCallback}
        aria-label={`Checkbox: ${!selected && 'not'} selected`}
      >
        {selected && <CheckIcon className={styles.checkbox__icon} />}
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Checkbox;
