//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// Assets ----------------------------------------------------------------------
import { PostPlaceholder } from '@img/posts';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Image extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fallback: false,
    };
  }

  render() {
    const { className, src, alt, customPlaceholder, ...rest } = this.props;
    const { fallback } = this.state;

    const componentClasses = classNames(styles.image, {
      [className]: className,
    });

    return (
      <img
        className={componentClasses}
        alt={alt}
        src={fallback || !src ? customPlaceholder || PostPlaceholder : src}
        onError={() => this.setState({ fallback: true })}
        {...rest}
      />
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Image;
