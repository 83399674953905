// classes/userValidator.js
// Implemented in a separate file just to keep the actual component organized.
//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
// `validator` provides a bunch of interesting functions. `isEmpty` is a simple
// length validation (that we can actually implement ourselves). I like it
// because it has no dependencies, around 500kb when unpacked and over 3m
// monthly downloads.
import { isEmpty, isEmail } from 'validator';
//------------------------------------------------------------------------------
// Classes ---------------------------------------------------------------------
import FormValidator from '@/classes/formValidator';
//------------------------------------------------------------------------------
// Constants -------------------------------------------------------------------
import { UserRoles } from '@helpers/constants/user';
import { userFormatter } from '@helpers/formatter';
//------------------------------------------------------------------------------
// Local -----------------------------------------------------------------------
const isSelectValid = (value) => value !== null && value.length > 0;
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export const userValidator = (user) => {
  const formattedUser = userFormatter(user);

  const items = [
    {
      key: 'firstName',
      defaultValue: '',
      method: isEmpty,
      validWhen: false,
      error: 'Enter a valid first name',
    },
    {
      key: 'lastName',
      defaultValue: '',
      method: isEmpty,
      validWhen: false,
      error: 'Enter a valid last name',
    },
    {
      key: 'email',
      defaultValue: '',
      method: isEmail,
      validWhen: true,
      error: 'Enter a valid email',
    },
    {
      key: 'organization',
      defaultValue: '',
      method: isEmpty,
      validWhen: false,
      error: 'Enter a valid organization',
    },
    {
      key: 'roles',
      defaultValue: null,
      method: (select) =>
        isSelectValid(select) ||
        (user && formattedUser.hasRole(UserRoles.SUPER_ADMIN)),
      validWhen: true,
      error: 'Select a role',
    },
  ];

  return new FormValidator(items);
};
