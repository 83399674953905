import { defaultClient, retryClient } from '@api/client';

export const likePost = ({ id }) => {
  return retryClient.post(`/posts/${id}/likes`);
};

export const createPost = ({
  images,
  post: { ignore_whitespace, ...post },
}) => {
  let formData = new FormData();
  formData.append('post', JSON.stringify(post));
  images.forEach((image) => formData.append('images', image));

  return defaultClient.post('/posts', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};
