//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Input extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFocused: false,
    };
  }

  render() {
    const { isFocused } = this.state;
    const {
      className,
      icon: IconComponent,
      onChange,
      invalid,
      ...inputProps
    } = this.props;

    const componentClasses = classNames(styles.input, {
      [styles['input--focused']]: isFocused,
      [styles['input--invalid']]: invalid,
      [className]: className,
    });

    return (
      <div className={componentClasses}>
        {IconComponent && (
          <div className={styles.input__icon}>
            <IconComponent />
          </div>
        )}
        <input
          type="text"
          {...inputProps}
          className={styles.input__element}
          onFocus={() => this.setState({ isFocused: true })}
          onBlur={() => this.setState({ isFocused: false })}
          onChange={({ currentTarget }) =>
            onChange ? onChange(currentTarget.value) : undefined
          }
        />
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Input;
