//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import Select from 'react-select';
import { FaLevelUpAlt as LevelUpIcon } from 'react-icons/fa';
import { navigate } from 'gatsby';
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Modal, ButtonKind } from '@cmp/common';
import { withAuthUser, withInfiniteScrolling } from '@cmp/common/hoc';
import { PostGrid, SearchBar } from '@cmp/authenticated';
//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------
import { Posts as PostsRequest } from '@api/endpoints/get';
import { deletePost as deletePostRequest } from '@api/endpoints/delete';
//------------------------------------------------------------------------------
// Cache -----------------------------------------------------------------------
import cacheClient from '@/cache/client';
//------------------------------------------------------------------------------
// Helpers & Constants ---------------------------------------------------------
//  External -------------------------------------------------------------------
import { PostStatus, PostAction } from '@helpers/constants/post';
import { ColorTone, ModelLine } from '@helpers/constants/car';
import {
  PorscheSelectTheme,
  PorscheSelectStyle,
} from '@helpers/constants/select';
import { constantSelectOptions } from '@helpers/select';
import { AccessControlList } from '@helpers/roles';
//  Local ----------------------------------------------------------------------
const ModalKind = {
  DeleteConfirmation: 'CONFIRMATION',
  Error: 'ERROR',
};
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchQuery: '',
      modal: {},
      modelLine: undefined,
      exteriorColorTone: undefined,
      currentPage: 1,
      hideNetworkStatus: false,
    };

    this.likePost = this.likePost.bind(this);
  }

  componentDidMount() {
    this.setupInfiniteScrolling();
  }

  setupInfiniteScrolling() {
    const {
      __infiniteScrolling: { shouldAnalyzeScrolling },
    } = this.props;
    shouldAnalyzeScrolling(() => false);
  }

  sectionComponent(title, IconComponent, children) {
    return (
      <section className={styles.home__section}>
        {(title || IconComponent) && (
          <header className={styles.section__header}>
            {IconComponent && (
              <IconComponent className={styles.section__icon} />
            )}
            <h1 className={styles.section__title}>{title}</h1>
          </header>
        )}
        {children}
      </section>
    );
  }

  headerComponent() {
    let that = this;
    const { searchQuery, modelLine, exteriorColorTone } = this.state;

    return (
      <header className={styles.home__header}>
        <SearchBar
          className={styles.home__search_bar}
          value={searchQuery}
          onChange={(searchQuery) => this.setState({ searchQuery })}
          onSearch={() =>
            this.setState({ currentPage: 1, searchQuery }, () => this.refetch())
          }
          onClear={() =>
            this.setState({ currentPage: 1, searchQuery: '' }, () => {
              that.loadingPages = true;
              that.refetch();
            })
          }
        />
        <Select
          className={styles.home__filter}
          theme={PorscheSelectTheme}
          styles={PorscheSelectStyle}
          options={constantSelectOptions(ModelLine)}
          value={modelLine}
          onChange={(modelLine) =>
            this.setState(
              { hideNetworkStatus: false, currentPage: 1, modelLine },
              () => {
                that.loadingPages = true;
                that.refetch();
              }
            )
          }
          placeholder="Model Line..."
          isClearable
        />
        <Select
          className={styles.home__filter}
          theme={PorscheSelectTheme}
          styles={PorscheSelectStyle}
          options={constantSelectOptions(ColorTone)}
          color={exteriorColorTone}
          onChange={(exteriorColorTone) =>
            this.setState(
              { hideNetworkStatus: false, currentPage: 1, exteriorColorTone },
              () => {
                that.loadingPages = true;
                that.refetch();
              }
            )
          }
          placeholder="Color Tone..."
          isClearable
        />
      </header>
    );
  }

  likePost(post) {
    const action = post.loginUserLiked ? PostAction.Unlike : PostAction.Like;

    action
      .method(post)
      .then((res) => {
        const transformedPost = action.transform(post);
        cacheClient.storeValue(transformedPost.id, transformedPost);
      })
      .catch((err) => console.error(err));
  }

  deletePost(post) {
    this.setState({ modal: {} });

    deletePostRequest(post)
      .then(() => this.refetch())
      .catch((err) => {
        this.setState({ modal: { kind: ModalKind.Error } });
      });
  }

  render() {
    let that = this;
    const {
      searchQuery,
      modelLine = {},
      exteriorColorTone = {},
      modal = {},
      currentPage,
      hideNetworkStatus,
    } = this.state;
    const {
      className,
      location,
      __authUser: { user } = {},
      __infiniteScrolling: { shouldAnalyzeScrolling, hasScrolled },
    } = this.props;

    const componentClasses = classNames(styles.home, {
      [className]: className,
    });

    return (
      <div className={componentClasses}>
        {/* Header --------------------------------------------------------- */}
        {this.headerComponent()}

        {/* Post Grid ------------------------------------------------------ */}
        <PostsRequest
          searchQuery={searchQuery}
          modelLine={modelLine && modelLine.value}
          exteriorColorTone={exteriorColorTone && exteriorColorTone.value}
          postStatus={PostStatus.PUBLISHED}
          stateIndicatorComponent={hideNetworkStatus ? null : undefined}
          page={currentPage}
          validPlaceholder={(data) =>
            (!data || data.empty) && 'Nothing yet. Create the first post!'
          }
          onComplete={(data) => {
            that.setState({ hideNetworkStatus: !data.empty });
            that.loadingPages = false;
          }}
          didUpdateRefetch={(refetch) => (this.refetch = refetch)}
        >
          {({
            loading,
            error,
            data: { content, first, last, totalPages } = {},
          }) => {
            // If the list is loading, if there is an error, if we're at the
            // last page or if there isn't a content, do not analyze the
            // scrolling.
            shouldAnalyzeScrolling(
              () => !that.loadingPages && !loading && !error && !last && content
            );

            // Gets notified when the user has scrolled enough to the bottom,
            // so that we can load another page
            hasScrolled(() => {
              // We're relying on an instance variable here because setting the
              // state might take a moment, and if the hasScrolled is triggered
              // again the next page will load. This is reset on onComplete.
              that.loadingPages = true;

              // Increase the current page, update the state and after that
              // refetch the data. Our PostsRequest will take care of passing
              // the right params.
              this.setState({ currentPage: currentPage + 1 }, () => {
                // After refetching, we'll transform the data to append the new
                // one to the end of the existing list.
                this.refetch((data) => {
                  data.content = [...content, ...data.content];
                  return data;
                });
              });
            });

            return this.sectionComponent(
              'Newest Posts',
              LevelUpIcon,
              <PostGrid
                posts={content}
                onLike={this.likePost}
                location={location}
                accessControl={AccessControlList(user)}
                onEdit={(post) =>
                  navigate(`/edit/${post.id}`, { state: { post } })
                }
                onDelete={(post) =>
                  this.setState({
                    modal: { kind: ModalKind.DeleteConfirmation, data: post },
                  })
                }
              />
            );
          }}
        </PostsRequest>

        {/* Delete Confirmation modal -------------------------------------- */}
        {modal.kind === ModalKind.DeleteConfirmation && (
          <Modal.Generic
            title="Delete Post"
            description={`Do you want to delete ${modal.data.title}? This action can't be undone.`}
            actions={[
              {
                title: 'Cancel',
                onClick: () => this.setState({ modal: {} }),
                kind: ButtonKind.Interrupt,
              },
              {
                title: 'Delete',
                onClick: () => this.deletePost(modal.data),
                kind: ButtonKind.Info,
              },
            ]}
            onClose={() => this.setState({ modal: {} })}
          />
        )}

        {/* Error modal ---------------------------------------------------- */}
        {modal.kind === ModalKind.Error && (
          <Modal.Error onClose={() => this.setState({ modal: {} })} />
        )}
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withAuthUser(withInfiniteScrolling(Home));
