//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
//------------------------------------------------------------------------------
// Constants -------------------------------------------------------------------
export const KeyboardKeys = {
  ESCAPE: 27,
};
//------------------------------------------------------------------------------
// HOC -------------------------------------------------------------------------
function withKeyboardEvents(keys, WrappedComponent) {
  class WithKeyboardEvents extends React.Component {
    constructor(props) {
      super(props);
      this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    componentDidMount() {
      document.addEventListener("keydown", this.handleKeyDown, false);
    }

    componentWillUnmount() {
      document.removeEventListener("keydown", this.handleKeyDown, false);
    }

    handleKeyDown(e) {
      const indexOf = keys.indexOf(e.keyCode);
      if (indexOf <= -1) return;

      if (!this.onKeyPress) return;
      this.onKeyPress(e.keyCode);
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          __keyboardEvents={{
            onKeyPress: (onKeyPress) => this.onKeyPress = onKeyPress
          }}
        />
      );
    }
  }

  WithKeyboardEvents.displayName = `WithKeyboardEvents(${getDisplayName(
    WrappedComponent
  )})`;

  return WithKeyboardEvents;
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default withKeyboardEvents;
