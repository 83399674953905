// PhotoEditor -----------------------------------------------------------------
// Used to edit a file (received via props). There are two supported behaviors:
// moving the image (within bounds) and scaling it up and down (also within
// bounds).
// Eventually the class should expose a `onImageChange` method.
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class PhotoEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      scale: 10,
      translate: { x: 0, y: 0 },
    };

    // Creates the reference to the canvas
    this.canvasRef = React.createRef();

    // Binds some of its most used methods
    this.setupCanvas = this.setupCanvas.bind(this);
    this.startDrag = this.startDrag.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
  }

  componentDidMount() {
    // When mounting the component set up the canvas and its mouse events
    this.setupCanvas();
    this.setupEvents();
  }

  setupEvents() {
    const canvas = this.canvasRef.current;
    if (!canvas) return;

    // To-do (P0): Unlisten to these events
    canvas.addEventListener('mousedown', this.startDrag);
    canvas.addEventListener('mousemove', this.handleDrag);
    canvas.addEventListener('mouseleave', () => (this.isDragging = false));
    canvas.addEventListener('mouseup', () => (this.isDragging = false));
  }

  // User has clicked down on the image
  startDrag(event) {
    this.isDragging = true;

    // Store the initial drag position
    this.dragStart = { x: event.pageX, y: event.pageY };
  }

  handleDrag(event) {
    // If the user isn't dragging, ignore it. This is required since what's
    // calling this method is, in fact, the `mousemove` event.
    if (!this.isDragging) return;

    // Get the current drag position
    this.dragEnd = { x: event.pageX, y: event.pageY };

    // Calculate the distance between the current drag and the original one
    // (its translation)
    const translateX = this.dragEnd.x - this.dragStart.x;
    const translateY = this.dragEnd.y - this.dragStart.y;

    // Update what's stored with that new translation (so every movement is an
    // extension of the previous). After that, setup the canvas again.
    const { translate } = this.state;
    this.setState(
      {
        translate: { x: translate.x - translateX, y: translate.y - translateY },
      },
      this.setupCanvas
    );

    // Finally, _reset_ the drag start
    this.dragStart = this.dragEnd;
  }

  endDrag(event) {
    this.isDragging = false;
  }

  componentDidUpdate(prevProps) {
    const { file } = this.props;

    if (file !== prevProps.file) {
      this.setupCanvas();
    }
  }

  setupCanvas() {
    // Get the file
    const { file } = this.props;
    if (!file) return;

    // Get the canvas
    const canvas = this.canvasRef.current;
    if (!canvas) return;

    // Get the canvas context
    const context = canvas.getContext('2d');

    // Create an image reference, and draw the image once it loads from the URL
    // generated by the file
    const image = new Image();
    image.onload = () => this.drawImage(context, image, canvas);

    const url = URL.createObjectURL(file);
    image.src = url;
  }

  drawImage(context, image, canvas) {
    // Get whatever selection the user's made (scale & translation)
    const { scale, translate } = this.state;

    // Reset the current canvas
    context.clearRect(0, 0, canvas.width, canvas.height);

    // Calculate the ideal scale so the image fits within canvas' bounds
    let contextScale = Math.max(
      canvas.width / image.width,
      canvas.height / image.height
    );

    // Also multiply that by the selected scale
    contextScale = contextScale * (scale / 10);

    // Position the image so it's in the center of the canvas
    const x = canvas.width / 2 - (image.width / 2) * contextScale;
    const y = canvas.height / 2 - (image.height / 2) * contextScale;

    // Get the image size with the scale applied
    const width = image.width * contextScale;
    const height = image.height * contextScale;

    // Calculate the ideal bound of the image
    let imageBoundY = canvas.height - height;
    let imageBoundX = canvas.width - width;

    // And finally get the ideal translation of the image, limiting that to the
    // origin (0, 0) and the max bound (imageBoundX, imageBoundY)
    let translatedX = Math.min(x - translate.x, 0);
    translatedX = Math.max(translatedX, imageBoundX);

    let translatedY = Math.min(y - translate.y, 0);
    translatedY = Math.max(translatedY, imageBoundY);

    // Draw the image in the context
    context.drawImage(image, translatedX, translatedY, width, height);

    // Update the parent with the new image
    // To-do (P0): this must be updated so that we capture the edited image only
    // when the user *clicks* to upload it, avoiding multiple state changes.
    const { canvasRef } = this.props;
    canvasRef(this.canvasRef);
  }

  render() {
    const { className } = this.props;
    const { scale } = this.state;

    const componentClasses = classNames(styles.photo_editor, {
      [className]: className,
    });

    return (
      <div className={componentClasses}>
        {/* To-do (P2): Accept all kinds of widths and heights here */}
        <canvas
          width="280"
          height="280"
          className={styles.photo_editor__canvas}
          ref={this.canvasRef}
        ></canvas>

        {/* To-do (P3): Add a container with all possible transformations, and,
          well, add more transformations (transparency, contracts, highlights etc) */}
        <input
          type="range"
          onChange={({ currentTarget: { value: scale } }) =>
            this.setState({ scale }, this.setupCanvas)
          }
          value={scale}
          min="10"
          max="100"
        />
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default PhotoEditor;
