// classes/postValidator.js
// Implemented in a separate file just to keep the actual component organized.
// If there are sepecific formatting for the comission number, vin, color code
// or anything like that it'll be extremely easy to update the `method` value.
//
// Something like:
// {
//  key: 'vin',
//  defaultValue: '',
//  method: (value) => /WPO(A|B)[1-9]{0,5}\w+/g.test(value),
//  validWhen: true,
//  error: 'Type a correct vin'
// }
//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
// `validator` provides a bunch of interesting functions. `isEmpty` is a simple
// length validation (that we can actually implement ourselves). I like it
// because it has no dependencies, around 500kb when unpacked and over 3m
// monthly downloads.
import { isEmpty } from 'validator';
//------------------------------------------------------------------------------
// Classes ---------------------------------------------------------------------
import FormValidator from '@/classes/formValidator';
//------------------------------------------------------------------------------
// Local -----------------------------------------------------------------------
const isSelectValid = (value) => value !== null;
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export const postValidator = () => {
  const items = [
    {
      key: 'title',
      defaultValue: '',
      method: isEmpty,
      validWhen: false,
      error: 'Enter a valid title',
    },
    {
      key: 'description',
      defaultValue: '',
      method: isEmpty,
      validWhen: false,
      error: 'Enter a valid description',
    },
    {
      key: 'modelLine',
      defaultValue: null,
      method: isSelectValid,
      validWhen: true,
      error: 'Select a model line',
    },
    {
      key: 'modelType',
      defaultValue: '',
      method: isEmpty,
      validWhen: false,
      error: 'Select a model type',
    },
    {
      key: 'modelYear',
      defaultValue: null,
      method: isSelectValid,
      validWhen: true,
      error: 'Select a model year',
    },
    {
      key: 'exteriorColorDescription',
      defaultValue: null,
      method: isSelectValid,
      validWhen: true,
      error: 'Select an exterior color code',
    },
    {
      key: 'exteriorColorCode',
      defaultValue: '',
      method: isEmpty,
      validWhen: false,
      error: 'Enter a valid exterior color',
    },
    {
      key: 'exteriorColorTone',
      defaultValue: null,
      method: isSelectValid,
      validWhen: true,
      error: 'Enter a valid color tone',
    },
    {
      key: 'interiorColor',
      defaultValue: '',
      method: isEmpty,
      validWhen: false,
      error: 'Enter a valid interior color',
    },
    {
      key: 'transmission',
      defaultValue: null,
      method: isSelectValid,
      validWhen: true,
      error: 'Select a transmission type',
    },
    {
      key: 'commissionNumber',
      defaultValue: '',
      method: isEmpty,
      validWhen: false,
      error: 'Enter a valid comission number',
    },
    {
      key: 'vin',
      defaultValue: '',
      method: isEmpty,
      validWhen: false,
      error: 'Enter a valid vin',
    },
    {
      key: 'images',
      defaultValue: [],
      // We can also add an upper limit to the images here. Or add a prop to
      // our ImageSelector.
      method: (images) => images.length > 0,
      validWhen: true,
      error: 'You must select at least one image',
    },
  ];

  return new FormValidator(items);
};
