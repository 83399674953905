//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class ModalBase extends React.Component {
  constructor(props) {
    super(props);
    this.modalRef = React.createRef();
  }

  componentDidMount() {
    this.setupBodyScroll();
  }

  setupBodyScroll() {
    const { current } = this.modalRef || {};
    if (!current) return;

    disableBodyScroll(current);
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }

  render() {
    const { className, onClose, children } = this.props;

    const componentClasses = classNames(styles.base_modal);

    const contentClasses = classNames(styles.base_modal__dialog, {
      [className]: className,
    });

    return (
      <div className={componentClasses} ref={this.modalRef}>
        <div
          className={styles.base_modal__overlay}
          onClick={onClose}
          onKeyDown={onClose}
          role="button"
          aria-label="Close dialog"
          tabIndex={0}
        ></div>
        <div className={contentClasses} role="dialog">
          {children}
        </div>
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default ModalBase;
