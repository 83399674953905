export const PorschePrimaryColor = '#cc0000';

export const PorscheSelectTheme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#fdecea',
    primary50: '#fce6e3',
    primary75: '#fce1de',
    primary: PorschePrimaryColor,
  },
});

export const PorscheSelectStyle = {
  container: (provided, state) => ({
    ...provided,

    // This seems to be a recurring issue with React Select. One hacky way to
    // fix it is by simply increasing the z-index.
    // Not a definite fix, but it will work.
    //
    // If it breaks, increase it. ⏫

    zIndex: state.isFocused ? 50 : 1,
  }),
};
