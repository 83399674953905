//------------------------------------------------------------------------------
// Gallery ---------------------------------------------------------------------
// This presents all the images of a post in a list, allowing the user to
// highlight or delete them individually. This should, ideally, be placed under
// the /post directory. Since that's a modal and this is a regular screen, I've
// decided to keep them separated for now.
//
// This screen leverages a bunch of other things that were implemented already,
// such as the generic GET component. To-do: add some comments so it's more
// easy to understand.
//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import {
  FaTrashAlt as DeleteIcon,
  FaStar as HighlightIcon,
} from 'react-icons/fa';
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Button, ButtonKind, Image } from '@cmp/common';
import { AuthenticatedPage } from '@cmp/authenticated';
//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------
import { Post as PostRequest } from '@api/endpoints/get';
import { deletePostImage } from '@api/endpoints/delete';
//------------------------------------------------------------------------------
// Helpers & Constants ---------------------------------------------------------
import { StatusMessages } from '@helpers/constants/api';
//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------
import { promoteToTitle } from '@api/endpoints/put';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Gallery extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadedPost: null,
      loading: false,
    };
  }

  updateItem(item, method) {
    this.setState({ loading: true });

    method(item)
      .then(() => this.refetch())
      .finally(() => this.setState({ loading: false }));
  }

  itemComponent(image, highlighted) {
    // In this specific scenario, it's probably a good idea to disable all items
    // if there's a request happening -- so that the user doesn't click it twice
    // or clicks to highlight/delete an item right as the list updates and the
    // indexes change.
    //
    // Also, promoting a picture and deleting are lightweight requests (204s)
    // that will complete in under a second for most people.

    const { loading } = this.state;

    return (
      <div className={styles.list__item} key={image.id}>
        <Image className={styles.item__image} src={image.imageUrl} />
        <div className={styles.item__actions}>
          {/* To-do (P3): Implement title image update; waiting for the API
              endpoint */}
          <Button
            kind={highlighted ? ButtonKind.Primary : ButtonKind.Secondary}
            disabled={loading}
            onClick={() => this.updateItem(image, promoteToTitle)}
          >
            <HighlightIcon />
          </Button>
          <Button
            kind={ButtonKind.Interrupt}
            disabled={loading}
            onClick={() => this.updateItem(image, deletePostImage)}
          >
            <DeleteIcon />
          </Button>
        </div>
      </div>
    );
  }

  listComponent(post) {
    const { postImages, titleImageUrl } = post || {};
    if (!postImages) return '';

    return (
      <div className={styles.gallery__list}>
        {postImages.map((image) =>
          this.itemComponent(image, image.imageUrl === titleImageUrl)
        )}
      </div>
    );
  }

  render() {
    const { className, location: { state } = {}, id } = this.props;
    const { post: cachedPost } = state || {};

    const { loadedPost } = this.state;
    const title =
      loadedPost || cachedPost
        ? `${(loadedPost || cachedPost).title} - Gallery`
        : 'Gallery';

    const componentClasses = classNames(styles.gallery, {
      [className]: className,
    });

    const shouldPresentStateIndicator =
      !cachedPost ||
      !cachedPost.postImages ||
      cachedPost.postImages.length <= 0 ||
      loadedPost;

    return (
      <AuthenticatedPage title={title} className={componentClasses}>
        <PostRequest
          id={id}
          onComplete={(loadedPost) => this.setState({ loadedPost })}
          validPlaceholder={(data = {}) =>
            (!data || !data.postImages || data.postImages.length <= 0) &&
            StatusMessages.Placeholder
          }
          didUpdateRefetch={(refetch) => (this.refetch = refetch)}
          {...(!shouldPresentStateIndicator && {
            stateIndicatorComponent: null,
          })}
          {...(cachedPost && { stateIndicatorComponent: null })}
        >
          {({ data }) => {
            const idealPost = data || cachedPost;
            if (!idealPost) return '';

            return (
              <div className={styles.gallery__content}>
                <span>
                  The star symbol represents the highlighted photo from the
                  post.
                </span>
                {this.listComponent(idealPost)}
              </div>
            );
          }}
        </PostRequest>
      </AuthenticatedPage>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Gallery;
