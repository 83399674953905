//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import memoryCache from 'memory-cache';
//------------------------------------------------------------------------------
// Class -----------------------------------------------------------------------
class Cache {
  contructor() {
    this.observers = [];
  }

  getValue(id) {
    const raw = memoryCache.get(id);

    try {
      const parsed = JSON.parse(raw);
      return parsed;
    } catch {
      return null;
    }
  }

  storeValue(id, value) {
    const stored = memoryCache.put(id, value);

    this.notifyObserver(id, value);

    return stored;
  }

  addObserver(id, callback) {
    const observer = { id, callback };

    if (!this.observers) this.observers = [];
    this.observers.push(observer);

    return observer;
  }

  removeObserver(observer) {
    if (!this.observers) return;

    const indexOf = this.observers.indexOf(observer);
    if (indexOf <= -1) return;

    this.observers.splice(indexOf, 1);
  }

  notifyObserver(id, value) {
    if (!this.observers) return;

    const filtered = this.observers.filter((observer) => observer.id === id);
    if (!filtered || filtered.length <= 0);

    filtered.forEach((observer) => {
      if (observer && observer.callback) {
        observer.callback(value);
      }
    });
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
const client = new Cache();
export default client;
