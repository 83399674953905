//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { navigate } from 'gatsby';
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { AuthenticatedPage } from '@cmp/authenticated';
import { Input, Select, Button, ButtonKind, Checkbox } from '@cmp/common';
//------------------------------------------------------------------------------
// Helpers & Constants ---------------------------------------------------------
import { constantSelectOptions } from '@helpers/select';
import { UserRoles, ReadableUserRoles } from '@helpers/constants/user';
import { StatusMessages } from '@helpers/constants/api';
import { PageMode } from '@pages/authenticated/users/create';
import { userFormatter } from '@helpers/formatter';
//------------------------------------------------------------------------------
// Classes ---------------------------------------------------------------------
import { userValidator } from '@/classes/userValidator';
//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------
import { createUser } from '@api/endpoints/post';
import { updateUser } from '@api/endpoints/put';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: null,
      values: {},
      invalidItems: [],
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.validateIfNeeded = this.validateIfNeeded.bind(this);
    this.formValidator = userValidator;
  }

  componentDidMount() {
    this.setupDataIfNeeded();
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;

    if (user !== prevProps.user) {
      this.setupDataIfNeeded();
    }
  }

  setupDataIfNeeded() {
    // Setting up the data means updating the state.values initially so that all
    // inputs have the proper value, if needed (if we're in edit mode; if there
    // is a user property received by the parent).

    const { user } = this.props;
    if (!user) return this.setState({ values: {} });

    // Converting the user role (an array of Strings) into an array that can be
    // read by the Select component.

    const roleOptions = constantSelectOptions(ReadableUserRoles);
    const selectedOptions = roleOptions.filter(
      ({ value }) => user.roles.indexOf(value) > -1
    );

    const values = {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      organization: user.organization,
      roles: selectedOptions,
    };

    this.setState({ values });
  }

  onSubmit(e) {
    e.preventDefault();

    const { values } = this.state;
    const { user, mode } = this.props;

    const { isValid, invalidItems } = this.formValidator(user).validateAll(values);

    if (!isValid) {
      this.setState({ invalidItems });
      return;
    }

    // The SUPER_ADMIN role is not visible in the UI, so we need to find a way
    // not to _remove_ it when updating an actual super admin (since the
    // options selected will never contain it).

    const formattedUser = userFormatter(user);
    const isSuperAdmin = formattedUser.hasRole(UserRoles.SUPER_ADMIN);

    // We're safely getting and using the values.roles array here. Syntax looks
    // a bit weird, but it's easy to follow. If the user is a super admin, the
    // roles will contain that, and then spread the selected roles
    // (values.roles will hardly be empty as we have a validation in place, but
    // it's probably a good idea to default it to an empty array so that map
    // doesn't break).
    const roles = isSuperAdmin ? [UserRoles.SUPER_ADMIN] : [];
    roles.push(...(values.roles || []).map(({ value }) => value));

    // Our payload consists of what's in the values (see the keys in
    // formComponent). Since we're storing the roles as an array of Select
    // options, we need to convert it.
    const payload = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      organization: values.organization,
      roles,
    };

    let updatedState = {
      loading: true,
      error: null,
    };

    this.setState(updatedState);

    const isCreate = mode === PageMode.CREATE;
    const method = isCreate
      ? createUser(payload)
      : updateUser(user.id, payload);

    method
      .then((res) => {
        // If everything goes well, simply go back to the previous page.
        return navigate('..');
      })
      .catch((err) => {
        // Otherwise, update the error value of the state.
        updatedState.error = StatusMessages.Error.Actionable;
      })
      .finally(() => {
        updatedState.loading = false;
        this.setState(updatedState);
      });
  }

  formComponent() {
    const {
      mode: { action },
    } = this.props;
    const { loading, error } = this.state;

    return (
      <form className={styles.form_page__form} onSubmit={this.onSubmit}>
        {/* To-do (P3): We need to find a way to make it share the same
            underlying input component from the `create` page. */}
        {this.inputComponent('First name', 'firstName', 'Type a first name')}
        {this.inputComponent('Last name', 'lastName', 'Type a last name')}
        {this.inputComponent('Email', 'email', 'Type an email')}
        {this.inputComponent(
          'Organization',
          'organization',
          'Type an organization'
        )}
        {this.selectComponent(
          'Roles',
          'roles',
          constantSelectOptions(ReadableUserRoles),
          { isMulti: true }
        )}
        <footer className={styles.form__footer}>
          <Button loading={loading} type="submit">
            {action.icon}
            <span>{action.title}</span>
          </Button>
          <Button kind={ButtonKind.Interrupt} onClick={() => navigate('..')}>
            Cancel
          </Button>
        </footer>
        {error && <span className={styles.form__error}>{error}</span>}
      </form>
    );
  }

  baseComponent(label, key, component) {
    const { invalidItems } = this.state;
    const currentInvalidItem =
      invalidItems && invalidItems.find((x) => x.key === key);

    return (
      <div className={styles.form__input}>
        <label className={styles.input__label}>{label}</label>
        {React.cloneElement(component, { invalid: currentInvalidItem })}
        {currentInvalidItem && (
          <span className={styles.input__error_message}>
            {currentInvalidItem.error}
          </span>
        )}
      </div>
    );
  }

  inputComponent(label, key, placeholder) {
    const { values } = this.state;

    const inputComponent = (
      <Input
        type="text"
        placeholder={placeholder}
        value={values[key] || ''}
        onChange={(value) => {
          // On every change, update the `values` object in our state. After
          // that, validate the form if needed (more info below).
          values[key] = value;
          this.setState({ values }, this.validateIfNeeded);
        }}
      />
    );

    return this.baseComponent(label, key, inputComponent);
  }

  checkboxComponent(label, key) {
    const { values } = this.state;

    const checkboxComponent = (
      <Checkbox
        selected={values[key]}
        onChange={(value) => {
          values[key] = value;
          this.setState({ values }, this.validateIfNeeded);
        }}
      />
    );

    return this.baseComponent(label, key, checkboxComponent);
  }

  selectComponent(label, key, options, props) {
    const { values } = this.state;

    const selectComponent = (
      <Select
        options={options}
        {...props}
        value={values[key]}
        onChange={(value) => {
          values[key] = value;
          this.setState({ values }, this.validateIfNeeded);
        }}
      />
    );

    return this.baseComponent(label, key, selectComponent);
  }

  validateIfNeeded() {
    const { user } = this.props;

    const { values, invalidItems: currentInvalidItems } = this.state;
    if (!currentInvalidItems || currentInvalidItems.length <= 0) return;

    const { invalidItems } = this.formValidator(user).validateAll(values);
    this.setState({ invalidItems });
  }

  render() {
    const { className, mode } = this.props;

    const componentClasses = classNames(styles.form_page, {
      [className]: className,
    });

    return (
      <AuthenticatedPage title={mode.title} className={componentClasses}>
        {this.formComponent()}
      </AuthenticatedPage>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Form;
