//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
//------------------------------------------------------------------------------
// Helpers & Constants ---------------------------------------------------------
import * as localStorage from '@helpers/localStorage';
import { UserStorageKey } from '@constants/localStorage';
//------------------------------------------------------------------------------
// HOC -------------------------------------------------------------------------
function withAuthUser(WrappedComponent) {
  class WithAuthUser extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        // Adding this here (although it's not recommended) to not break
        // existing component that rely on __authUser always existing (since
        // they're in the authenticated route).
        user: localStorage.getValue(UserStorageKey, true)
      };

      this.userChanged = this.userChanged.bind(this);
    }

    componentDidMount() {
      localStorage.addObserver(UserStorageKey, this.userChanged);
    }

    componentWillUnmount() {
      localStorage.removeObserver(UserStorageKey, this.userChanged);
    }

    userChanged(rawUser) {
      let parsedUser;

      try {
        parsedUser = JSON.parse(rawUser);
      } catch(e) {
        return;
      }

      this.setState({ user: parsedUser });
    }

    render() {
      const { user } = this.state;
      return <WrappedComponent {...this.props} __authUser={user} />;
    }
  }

  WithAuthUser.displayName = `WithAuthUser(${getDisplayName(
    WrappedComponent
  )})`;

  return WithAuthUser;
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default withAuthUser;
