//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import GET from '@api/methods/get';
//------------------------------------------------------------------------------
// Endpoint --------------------------------------------------------------------
const Request = {
  endpoint: '/posts',
};
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
export const Posts = ({
  children,
  searchQuery,
  modelLine,
  exteriorColorTone,
  postStatus,
  page,
  params,
  ...props
}) => {
  const requestParams = {
    ...params,
    modelLine,
    exteriorColorTone,
    postStatus,
    page,
  };

  if (searchQuery && searchQuery.length > 0) {
    requestParams.search = searchQuery;
  }

  return (
    <GET
      request={Request}
      params={requestParams}
      cache={{
        dataIdsFromData: (data) => data.content.map((x) => x.id),
        updateObject: (data, post) => {
          // A post's been updated and the GET component is giving us (the
          // `updateObject`) the ability to update our existing records.

          // First, validate the data just to be safe
          if (!data || !data.content) return data;

          // We know that data.content is an array of posts, so we'll try
          // finding its index.
          const foundIndex = data.content.findIndex(
            (item) => item.id === post.id
          );
          if (foundIndex <= -1) return data;

          // If we found it, replace the whole record.
          data.content[foundIndex] = post;
          return data;
        },
      }}
      {...props}
    >
      {children}
    </GET>
  );
};
