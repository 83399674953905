//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import { Router } from '@reach/router';
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { PrivateRoute } from '@cmp/common';
import Welcome from './welcome';
import Authenticated from './authenticated';
import { withQueryParams, mountClient } from '@cmp/common/hoc';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { auth } from '@helpers/auth';
//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------
import { authenticate } from '@api/endpoints/post';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class App extends React.Component {
  componentDidMount() {
    // Verify if the user should be updated
    this.updateUserIfNeeded();
  }

  componentDidUpdate(prevProps) {
    const { __searchParams = {} } = this.props;
    const { __searchParams: { token: prevToken } = {} } = prevProps;

    // Compare the previous searchParam's token with the current one. If they've
    // changed (componentDidUpdate might've been called for different reasons),
    // verify if the user should be updated.

    if (__searchParams.token !== prevToken) {
      this.updateUserIfNeeded();
    }
  }

  updateUserIfNeeded() {
    const { __searchParams: { token } = {} } = this.props;
    if (!token) return;

    // Porsche Exclusive Gallery allows admins to log in to specific accounts by
    // just adding the user's JWT token to the URL.

    authenticate(token).then(({ data }) => auth(data));
  }

  render() {
    return (
      <Router>
        <PrivateRoute
          path="/*"
          component={Authenticated}
          publicComponent={Welcome}
        />
      </Router>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withQueryParams(
  [{ key: 'token', required: false, exceptPaths: ['/activate'] }],
  mountClient(App)
);
