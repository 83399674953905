//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { FaLock as LockIcon, FaUserAlt as UserIcon } from 'react-icons/fa';
import { isEmail, isEmpty } from 'validator';
import { navigate } from 'gatsby';
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Input, Button, ButtonKind, Feedback, FeedbackKind } from '@cmp/common';
//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------
import { signIn } from '@api/endpoints/post';
//------------------------------------------------------------------------------
// Helpers & Classes -----------------------------------------------------------
import { auth } from '@helpers/auth';
import FormValidator from '@/classes/formValidator';
import { validationFormatter } from '@helpers/formatter';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class SignIn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      loading: false,
      feedback: null,
    };
  }

  componentDidMount() {
    const items = [
      {
        key: 'email',
        defaultValue: '',
        method: isEmail,
        validWhen: true,
        error: 'Enter a valid email',
      },
      {
        key: 'password',
        defaultValue: '',
        method: isEmpty,
        validWhen: false,
        error: 'Enter a valid password',
      },
    ];

    this.validator = new FormValidator(items);
  }

  onSubmit(e) {
    const { email, password } = this.state;

    // Ignore the default behavior when submitting a form
    if (e) e.preventDefault();

    // Validate all fields and update the error
    const validateAll = this.validator.validateAll({ email, password });

    // Get the formatted validation (a combination of helper methods based on
    // the result)
    const formattedValidation = validationFormatter(validateAll);

    // Update the state based on the result
    this.setState({
      feedback: {
        title: 'Check your information',
        text: formattedValidation.list(),
        kind: FeedbackKind.Error,
      },
    });

    // If the form isn't valid, do not submit anything
    if (!validateAll.isValid) return;

    // Update the state so the button shows a spinner and becomes unavailable
    let that = this;
    let response = { loading: true, feedback: null };

    this.setState({ ...response });

    // Submit data
    signIn({ email, password })
      .then(({ data }) => auth(data))
      .catch(() => {
        // To-do (P2): Check status code; ideally in a middleware?
        response.feedback = {
          title: 'Invalid credentials',
          text:
            'The email or password is incorrect. Please review them and try again.',
          kind: FeedbackKind.Error,
        };
      })
      .finally(() => {
        response.loading = false;
        that.setState({ ...response });
      });
  }

  render() {
    const { email, password, loading, feedback } = this.state;
    const { className } = this.props;

    const componentClasses = classNames(styles.signIn, {
      [className]: className,
    });

    return (
      <form className={componentClasses} onSubmit={(e) => this.onSubmit(e)}>
        <Input
          placeholder="Email"
          type="email"
          icon={UserIcon}
          onChange={(email) => this.setState({ email })}
          value={email}
        />
        <Input
          placeholder="Password"
          icon={LockIcon}
          type="password"
          onChange={(password) => this.setState({ password })}
          value={password}
        />
        <Button
          className={styles.signIn__button}
          type="submit"
          loading={loading}
        >
          <LockIcon className={styles.button__icon} />
          <span>Log In</span>
        </Button>
        <div className={styles.signIn__buttons}>
          <Button
            kind={ButtonKind.Secondary}
            onClick={() => navigate('/signup')}
          >
            Sign Up
          </Button>
          <Button
            kind={ButtonKind.Secondary}
            onClick={() => navigate('/forgot', { state: { email } })}
          >
            Forgot Password?
          </Button>
        </div>
        {feedback && <Feedback {...feedback} />}
      </form>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default SignIn;
