//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import {
  FaLayerGroup as CreateIcon,
  FaImages as GalleryIcon,
  FaEye as ViewIcon,
  FaPen as EditIcon,
  FaTrashAlt as DeleteIcon,
  FaInfoCircle as InfoIcon,
} from 'react-icons/fa';
import { navigate } from 'gatsby';
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from './index.scss';
import sharedStyles from '@styles/common/_shared.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Button, ButtonKind, Image, Modal, Pagination } from '@cmp/common';
import { AuthenticatedPage, PageSwitch } from '@cmp/authenticated';
import { withAuthUser } from '@cmp/common/hoc';
//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------
import { Posts as PostsRequest } from '@api/endpoints/get';
import { deletePost as deletePostRequest } from '@api/endpoints/delete';
//------------------------------------------------------------------------------
// Helpers & Constants ---------------------------------------------------------
//  External -------------------------------------------------------------------
import { DetailedPostStatus } from '@helpers/constants/post';
import { StatusMessages } from '@helpers/constants/api';
import { postFormatter } from '@helpers/formatter';
import { PostStatus } from '@helpers/constants/post';
//  Local ----------------------------------------------------------------------
const ModalKind = {
  DeleteConfirmation: 'CONFIRMATION',
  Error: 'ERROR',
  Info: 'INFO',
};
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Posts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      updating: [],
      modal: {},
      currentPage: 1,
      status: PostStatus.CREATED,
    };

    this.rowComponent = this.rowComponent.bind(this);
  }

  headerComponent() {
    return (
      <thead className={sharedStyles.table__header}>
        <tr className={sharedStyles.header__row}>
          <th>Thumbnail</th>
          <th>Title</th>
          <th>Description</th>
          <th>Model Line</th>
          <th>Model Type</th>
          <th>Model Year</th>
          <th>Exterior Color Code</th>
          <th>Exterior Color Description</th>
          <th>Interior color</th>
          <th>Transmission</th>
          <th>Commission number</th>
          <th>VIN</th>
          <th>Status</th>
          <th aria-label="Actions"></th>
        </tr>
      </thead>
    );
  }

  rowComponent(item) {
    // To-do (P0): Add fallback to values? “N/A”? Or will the post/create page
    // take care of it?

    const { location } = this.props;
    const formattedPost = postFormatter(item);

    const { updating } = this.state;
    const updatingItem = updating.findIndex((x) => x.id === item.id) > -1;

    // ReadablePostStatus will return a function that can be called with the
    // post. We should investigate a way to pass the post only once.
    const readablePostStatus = DetailedPostStatus(item);

    return (
      <tr className={sharedStyles.table__row} key={item.id}>
        <td
          className={classNames(
            sharedStyles.row__data,
            sharedStyles.row__thumbnail
          )}
        >
          <Image alt="Post thumbnail" src={item.titleImageUrl} />
        </td>
        <td
          className={classNames(
            sharedStyles.row__data,
            sharedStyles[`row__data--medium`]
          )}
        >
          {item.title}
        </td>
        <td
          className={classNames(
            sharedStyles.row__data,
            sharedStyles[`row__data--large`]
          )}
        >
          {formattedPost.trimmedDescription}
        </td>
        <td className={sharedStyles.row__data}>{item.modelLine}</td>
        <td className={sharedStyles.row__data}>{item.modelType}</td>
        <td className={sharedStyles.row__data}>{item.modelYear}</td>
        <td className={sharedStyles.row__data}>
          {item.exteriorColorDescription}
        </td>
        <td className={sharedStyles.row__data}>{item.exteriorColorCode}</td>
        <td className={sharedStyles.row__data}>{item.interiorColor}</td>
        <td className={sharedStyles.row__data}>{item.transmission}</td>
        <td className={sharedStyles.row__data}>{item.commissionNumber}</td>
        <td className={sharedStyles.row__data}>{item.vin}</td>
        <td className={sharedStyles.row__data}>
          {readablePostStatus && (
            <div className={styles.data__status}>
              <span className={styles.status__formatted}>
                {readablePostStatus.formatted}
              </span>
              {readablePostStatus.modal && (
                <InfoIcon
                  onClick={() =>
                    this.setState({
                      modal: {
                        kind: ModalKind.Info,
                        data: readablePostStatus.modal,
                      },
                    })
                  }
                  className={styles.status__icon}
                />
              )}
            </div>
          )}
        </td>
        <td className={sharedStyles.row__data}>
          <div className={sharedStyles.row__actions}>
            <Button
              kind={ButtonKind.Interrupt}
              onClick={() =>
                navigate(`/post/${item.id}/gallery`, { state: { post: item } })
              }
            >
              <GalleryIcon />
            </Button>
            <Button
              kind={ButtonKind.Interrupt}
              onClick={() =>
                navigate(`/post/${item.id}`, {
                  state: { modal: true, from: location, post: item },
                })
              }
            >
              <ViewIcon />
            </Button>
            <Button
              kind={ButtonKind.Info}
              onClick={() =>
                navigate(`/edit/${item.id}`, { state: { post: item } })
              }
            >
              <EditIcon />
            </Button>
            <Button
              kind={ButtonKind.Primary}
              disabled={updatingItem}
              onClick={() =>
                this.setState({
                  modal: { kind: ModalKind.DeleteConfirmation, data: item },
                })
              }
            >
              <DeleteIcon />
            </Button>
          </div>
        </td>
      </tr>
    );
  }

  tableComponent({ content } = {}) {
    if (!content) return '';

    return (
      <div className={sharedStyles.table_wrapper}>
        <table className={sharedStyles.table}>
          {this.headerComponent()}
          <tbody className={sharedStyles.table__body}>
            {content.map(this.rowComponent)}
          </tbody>
        </table>
      </div>
    );
  }

  deletePost(item) {
    let { updating } = this.state;

    if (updating.findIndex((x) => x.id === item.id) <= -1) {
      updating.push(item);
    }

    this.setState({ updating, modal: {} });

    deletePostRequest(item)
      .then(() => this.refetch())
      .catch((err) => {
        const modal = { kind: ModalKind.Error };

        const indexOf = updating.findIndex((x) => x.id === item.id);
        if (indexOf <= -1) return;

        updating.splice(indexOf, 1);
        this.setState({ updating, modal });
      });
  }

  render() {
    const {
      className,
      __authUser: { user },
    } = this.props;

    const { modal, status, currentPage } = this.state;

    const componentClasses = classNames(styles.posts, {
      [className]: className,
    });

    return (
      <AuthenticatedPage
        title="My Posts"
        description="Your most recent posts that are not published yet."
        actions={[{ label: 'New Post', to: '/create', icon: CreateIcon }]}
        className={componentClasses}
      >
        {/* Page switch ---------------------------------------------------- */}
        {/* This is a different approach for the PageSwitch component. Instead
            of linking to actual paths, it simply creates buttons with a
            onClick callback. */}
        <PageSwitch
          pages={[
            {
              title: 'Pending',
              selected: status === PostStatus.CREATED,
              onClick: () =>
                this.setState(
                  { status: PostStatus.CREATED, currentPage: 1 },
                  () => this.refetch()
                ),
            },
            {
              title: 'Denied',
              selected: status === PostStatus.DENIED,
              onClick: () =>
                this.setState(
                  { status: PostStatus.DENIED, currentPage: 1 },
                  () => this.refetch()
                ),
            },
          ]}
        />

        {/* Posts table ---------------------------------------------------- */}
        <PostsRequest
          params={{
            userId: user.id,
          }}
          // To-do: Find a way to load both DENIED and PENDING posts at the same
          // time. Should we split these into two different requests? What about
          // pagination? Maybe separate them into different table components?
          //
          postStatus={status}
          page={currentPage}
          validPlaceholder={({ empty } = {}) =>
            empty && StatusMessages.Placeholder
          }
          didUpdateRefetch={(refetch) => (this.refetch = refetch)}
        >
          {({ data = {} }) => {
            const { first, last, totalPages } = data;

            return (
              <div className={styles.posts__content}>
                {this.tableComponent(data)}
                <Pagination
                  className={styles.posts__pagination}
                  first={first}
                  last={last}
                  total={totalPages}
                  current={currentPage}
                  onChange={(currentPage) =>
                    this.setState({ currentPage }, () => this.refetch())
                  }
                />
              </div>
            );
          }}
        </PostsRequest>

        {/* Delete Confirmation modal -------------------------------------- */}
        {modal.kind === ModalKind.DeleteConfirmation && (
          <Modal.Generic
            title="Delete Post"
            description={`Do you want to delete ${modal.data.title}? This action can't be undone.`}
            actions={[
              {
                title: 'Cancel',
                onClick: () => this.setState({ modal: {} }),
                kind: ButtonKind.Interrupt,
              },
              {
                title: 'Delete',
                onClick: () => this.deletePost(modal.data),
                kind: ButtonKind.Info,
              },
            ]}
            onClose={() => this.setState({ modal: {} })}
          />
        )}

        {/* Error modal ---------------------------------------------------- */}
        {modal.kind === ModalKind.Error && (
          <Modal.Error onClose={() => this.setState({ modal: {} })} />
        )}

        {/* Delete Confirmation modal -------------------------------------- */}
        {modal.kind === ModalKind.Info && (
          <Modal.Generic
            title={modal.data.title}
            description={modal.data.description}
            actions={[
              {
                title: 'Close',
                onClick: () => this.setState({ modal: {} }),
                kind: ButtonKind.Interrupt,
              },
            ]}
            onClose={() => this.setState({ modal: {} })}
          />
        )}
      </AuthenticatedPage>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withAuthUser(Posts);
