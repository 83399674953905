//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { navigate } from 'gatsby';
import { FaTimes as CloseIcon } from 'react-icons/fa';
import { Helmet } from 'react-helmet';
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from './index.scss';
import sharedStyles from '@styles/common/_shared.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Modal, Slideshow } from '@cmp/common';
import { withKeyboardEvents, KeyboardKeys } from '@cmp/common/hoc';
//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------
import { Post as PostRequest } from '@api/endpoints/get';
//------------------------------------------------------------------------------
// Helpers & Constants ---------------------------------------------------------
import { userFormatter, postFormatter } from '@helpers/formatter';
import { PostAction } from '@helpers/constants/post';
//------------------------------------------------------------------------------
// Cache -----------------------------------------------------------------------
import cacheClient from '@/cache/client';
import { cacheImages } from '@/cache/static';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Post extends React.Component {
  constructor(props) {
    super(props);
    this.onClose = this.onClose.bind(this);
  }

  componentDidMount() {
    this.setupKeyboardEvents();
  }

  setupKeyboardEvents() {
    const { __keyboardEvents } = this.props;

    // We can safely ignore the first parameter (key code) since this only
    // listens to a single key (ESCAPE).
    __keyboardEvents.onKeyPress(this.onClose);
  }

  tableComponent(post) {
    function tableRowComponent(label, value) {
      return (
        <tr className={styles.table__row}>
          <td className={styles.row__label}>{label}</td>
          <td className={styles.row__value}>{value}</td>
        </tr>
      );
    }

    return (
      <div
        className={classNames(sharedStyles.table_wrapper, styles.post__table)}
      >
        <table className={styles.details__table}>
          <tbody>
            {tableRowComponent('Model Line', post.modelLine)}
            {tableRowComponent('Model Type', post.modelType)}
            {tableRowComponent('Model Year', post.modelYear || 'N/A')}
            {tableRowComponent('Exterior Color', post.exteriorColorCode)}
            {tableRowComponent(
              'Exterior Color Description',
              post.exteriorColorDescription
            )}
            {tableRowComponent('Interior Color', post.interiorColor)}
            {tableRowComponent('Transmission', post.transmission)}
            {tableRowComponent('Comission Number', post.commissionNumber)}
            {tableRowComponent('VIN', post.vin)}
          </tbody>
        </table>
      </div>
    );
  }

  detailsComponent(post) {
    return (
      <div className={styles.post__details}>
        <header className={styles.details__header}>
          <div className={styles.header__main}>
            <h1 className={styles.details__title}>{post.title}</h1>
            <CloseIcon
              className={styles.details__close}
              role="button"
              tabIndex={0}
              aria-label="Close dialog"
              onClick={this.onClose}
            />
          </div>
          <span className={styles.details__author}>
            by {userFormatter(post.user).fullName()}
          </span>
          <span className={styles.details__created_on}>
            {postFormatter(post).createdOn().fromNow}
          </span>
        </header>
        <div className={styles.details__body}>
          <span className={styles.details__description}>
            {post.description}
          </span>
          {this.tableComponent(post)}
        </div>
      </div>
    );
  }

  toggleLike(post) {
    const action = post.loginUserLiked ? PostAction.Unlike : PostAction.Like;

    action
      .method(post)
      .then((res) => {
        const transformedPost = action.transform(post);
        cacheClient.storeValue(transformedPost.id, transformedPost);
      })
      .catch((err) => console.error(err));
  }

  onClose() {
    const { location: { state } = {} } = this.props;
    const { from = {} } = state || {};

    navigate(from.pathname || '/');
  }

  render() {
    const { className, location: { state } = {}, id } = this.props;

    const { post: cachedPost } = state || {};

    const componentClasses = classNames(styles.post, {
      [className]: className,
    });

    return (
      <Modal.Base className={componentClasses} onClose={this.onClose}>
        <PostRequest
          id={id}
          onComplete={({ postImages = [] }) =>
            cacheImages(postImages.map((x) => x.imageUrl))
          }
          didUpdateRefetch={(refetch) => (this.refetch = refetch)}
          {...(cachedPost && { stateIndicatorComponent: null })}
        >
          {({ data }) => {
            const idealPost = data || cachedPost;
            if (!idealPost) return '';

            const formattedUser = userFormatter(idealPost.user);

            return (
              <>
                <Helmet>
                  <title>
                    {idealPost.title} by {formattedUser.fullName()}
                  </title>
                </Helmet>

                <Slideshow
                  className={styles.post__slideshow}
                  images={idealPost.postImages}
                  defaultImage={idealPost.titleImageUrl}
                  likeCount={idealPost.likes}
                  liked={idealPost.loginUserLiked}
                  viewCount={idealPost.viewCount}
                  toggleLike={() => this.toggleLike(idealPost)}
                />
                {this.detailsComponent(idealPost)}
              </>
            );
          }}
        </PostRequest>
      </Modal.Base>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withKeyboardEvents([KeyboardKeys.ESCAPE], Post);
