//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from './index.scss';
import sharedStyles from '@styles/common/_shared.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { AuthenticatedPage } from '@cmp/authenticated';
//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------
import { AdminPostViews as PostViewsRequest } from '@api/endpoints/get';
//------------------------------------------------------------------------------
// Helpers & Constants ---------------------------------------------------------
import { StatusMessages } from '@helpers/constants/api';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Popular extends React.Component {
  headerComponent() {
    return (
      <thead className={sharedStyles.table__header}>
        <tr className={sharedStyles.header__row}>
          <th>User</th>
          <th>Posts title</th>
          <th>Hits</th>
        </tr>
      </thead>
    );
  }

  rowComponent(data) {
    return (
      <tr className={sharedStyles.table__row} key={data.id}>
        <td className={sharedStyles.row__data}>
          {data.userFirstName} {data.userLastName}
        </td>
        <td className={sharedStyles.row__data}>{data.title}</td>
        <td className={sharedStyles.row__data}>{data.viewCnt}</td>
      </tr>
    );
  }

  tableComponent(data = []) {
    return (
      <div className={sharedStyles.table_wrapper}>
        <table className={classNames(sharedStyles.table)}>
          {this.headerComponent()}
          <tbody className={sharedStyles.table__body}>
            {data.map((x) => this.rowComponent(x))}
          </tbody>
        </table>
      </div>
    );
  }

  render() {
    const { className } = this.props;

    const componentClasses = classNames(styles.popular, {
      [className]: className,
    });

    return (
      <AuthenticatedPage
        title="Most Popular Posts"
        className={componentClasses}
      >
        <PostViewsRequest
          validPlaceholder={(data = {}) =>
            (!data || data.length <= 0) && StatusMessages.Placeholder
          }
        >
          {({ data }) => this.tableComponent(data)}
        </PostViewsRequest>
      </AuthenticatedPage>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Popular;
