//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class PageSwitch extends React.Component {
  singlePageComponent(page) {
    if (page.onClick) {
      const componentClasses = classNames(styles.page_switch__link, {
        [styles[`page_switch__link--active`]]: page.selected,
      });

      return (
        <button
          className={componentClasses}
          onClick={page.onClick}
          key={page.title}
        >
          {page.title}
        </button>
      );
    }

    return (
      <Link
        to={page.to}
        key={page.title + page.to}
        className={styles.page_switch__link}
        activeClassName={styles['page_switch__link--active']}
        partiallyActive={!page.exact}
      >
        {page.title}
      </Link>
    );
  }

  render() {
    const { className, pages } = this.props;
    if (!pages) return '';

    const componentClasses = classNames(styles.page_switch, {
      [className]: className,
    });

    return (
      <div className={componentClasses}>
        {pages.map((page) => this.singlePageComponent(page))}
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default PageSwitch;
