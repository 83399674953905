//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import {
  FaThumbsUp as ApproveIcon,
  FaThumbsDown as RejectIcon,
} from 'react-icons/fa';
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from './index.scss';
import sharedStyles from '@styles/common/_shared.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Button, ButtonKind, Modal } from '@cmp/common';
import { AuthenticatedPage } from '@cmp/authenticated';
import { withAuthUser } from '@cmp/common/hoc';
//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------
import { AdminUsers as UsersRequest } from '@api/endpoints/get';
import { approveUser, denyUser } from '@api/endpoints/put';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { userFormatter } from '@helpers/formatter';
//------------------------------------------------------------------------------
// Constants -------------------------------------------------------------------
//  External -------------------------------------------------------------------
import { Status as UserStatus } from '@helpers/constants/user';
import { StatusMessages } from '@helpers/constants/api';
//  Local ----------------------------------------------------------------------
const ModalKind = {
  Deny: 'DENY',
  Feedback: 'FEEDBACK',
  Error: 'ERROR',
};

const PendingAction = {
  Approve: {
    method: approveUser,
    modal: (user) => ({
      title: 'Success!',
      description: `${user.firstName} was approved.`,
    }),
  },
  Deny: {
    method: denyUser,
    modal: (user) => ({
      title: 'Denied User',
      description: `${user.firstName} was denied.`,
    }),
  },
};
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Pending extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: {},
      updating: [],
    };

    this.rowComponent = this.rowComponent.bind(this);
  }

  headerComponent() {
    return (
      <thead className={sharedStyles.table__header}>
        <tr className={sharedStyles.header__row}>
          <th>Full name</th>
          <th>Role</th>
          <th>Email</th>
          <th>Organization</th>
          <th aria-label="Actions"></th>
        </tr>
      </thead>
    );
  }

  rowComponent(user) {
    const { updating = [] } = this.state;

    const formattedUser = userFormatter(user);
    const isUpdating = updating.indexOf(user.id) > -1;

    return (
      <tr className={sharedStyles.table__row} key={user.id}>
        <td className={sharedStyles.row__data}>{formattedUser.fullName()}</td>
        <td className={sharedStyles.row__data}>
          {formattedUser.roles().map((x) => (
            <span key={x} className={styles.row__role}>
              {x}
            </span>
          ))}
        </td>
        <td className={sharedStyles.row__data}>
          <a href={`mailto:${user.email}`}>{user.email}</a>
        </td>
        <td className={sharedStyles.row__data}>{user.organization}</td>
        <td className={sharedStyles.row__data}>
          <div className={sharedStyles.row__actions}>
            <Button
              kind={ButtonKind.Primary}
              onClick={() =>
                this.setState({
                  modal: { kind: ModalKind.Deny, data: { user } },
                })
              }
              disabled={isUpdating}
            >
              <RejectIcon />
            </Button>
            <Button
              kind={ButtonKind.Allow}
              onClick={() => this.updateUser(PendingAction.Approve, user)}
              disabled={isUpdating}
            >
              <ApproveIcon />
            </Button>
          </div>
        </td>
      </tr>
    );
  }

  updateUser(action, user, payload) {
    const { updating = [] } = this.state;
    updating.push(user.id);

    this.setState({ updating });

    let modal = {};

    action
      .method(user.id, payload)
      .then(() => {
        this.refetch();

        modal = {
          kind: ModalKind.Feedback,
          data: action.modal(user),
        };
      })
      .catch((err) => {
        modal = { kind: ModalKind.Error };
      })
      .finally(() => {
        const indexOf = updating.indexOf(user.id);
        if (indexOf <= -1) return this.setState({ modal });

        updating.splice(indexOf, 1);
        this.setState({ updating, modal });
      });
  }

  tableComponent(data) {
    const { content } = data || {};
    if (!content || content.length <= 0) return '';

    return (
      <div className={sharedStyles.table_wrapper}>
        <table className={classNames(styles.all__table, sharedStyles.table)}>
          {this.headerComponent()}
          <tbody className={sharedStyles.table__body}>
            {content.map(this.rowComponent)}
          </tbody>
        </table>
      </div>
    );
  }

  denialReasonComponent() {
    const { modal } = this.state;
    if (modal.kind !== ModalKind.Deny) return '';

    const { data = {} } = modal;

    return (
      <Modal.TextArea
        title="Please enter a deny reason"
        actions={[
          {
            title: 'Cancel',
            onClick: () => this.setState({ modal: {} }),
            kind: ButtonKind.Interrupt,
          },
          {
            title: 'Deny',
            onClick: () =>
              this.updateUser(PendingAction.Deny, data.user, data.value),
            disabled: !data.value || data.value.length <= 0,
          },
        ]}
        value={data.value || ''}
        onChange={(value) => {
          data.value = value;
          modal.data = data;

          this.setState({ modal });
        }}
        onClose={() => this.setState({ modal: {} })}
      />
    );
  }

  render() {
    const { className } = this.props;
    const { modal = {} } = this.state;

    const componentClasses = classNames(styles.all, {
      [className]: className,
    });

    return (
      <AuthenticatedPage title="Pending Users" className={componentClasses}>
        {/* User table ----------------------------------------------------- */}
        <UsersRequest
          status={UserStatus.PENDING}
          validPlaceholder={({ empty = true } = {}) =>
            empty && StatusMessages.Placeholder
          }
          didUpdateRefetch={(refetch) => (this.refetch = refetch)}
        >
          {({ data, refetch }) => this.tableComponent(data)}
        </UsersRequest>

        {/* Feedback modal ------------------------------------------------- */}
        {modal.kind === ModalKind.Feedback && (
          <Modal.Generic
            {...modal.data}
            onClose={() => this.setState({ modal: {} })}
          />
        )}

        {/* Deny modal ----------------------------------------------------- */}
        {this.denialReasonComponent()}

        {/* Error modal ---------------------------------------------------- */}
        {modal.kind === ModalKind.Error && (
          <Modal.Error onClose={() => this.setState({ modal: {} })} />
        )}
      </AuthenticatedPage>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withAuthUser(Pending);
