//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { Router, Redirect } from '@reach/router';
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import Pending from './pending';
import Denied from './denied';
import { PageSwitch } from '@cmp/authenticated';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Publish extends React.Component {
  render() {
    const { className, location } = this.props;

    const componentClasses = classNames(styles.publish, {
      [className]: className,
    });

    return (
      <div className={componentClasses}>
        <PageSwitch
          pages={[
            { exact: true, to: '/publish', title: 'Publish' },
            { exact: true, to: '/publish/denied', title: 'Denied' },
          ]}
        />
        <div className={styles.publish__page}>
          <Router location={location}>
            <Pending path="/" />
            <Denied path="/denied" />
            <Redirect from="*" to="/publish" noThrow />
          </Router>
        </div>
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Publish;
