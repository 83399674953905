//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class TextArea extends React.Component {
  render() {
    const { className, onChange, invalid, ...rest } = this.props;

    const componentClasses = classNames(styles.text_area, {
      [styles['text_area--invalid']]: invalid,
      [className]: className,
    });

    return (
      <textarea
        {...rest}
        className={componentClasses}
        onChange={({ currentTarget }) => onChange(currentTarget.value)}
      ></textarea>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default TextArea;
