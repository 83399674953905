//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import { Link } from 'gatsby';
import { Router, Redirect } from '@reach/router';
import { Helmet } from 'react-helmet';
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
//  Pages ----------------------------------------------------------------------
import SignIn from './signIn';
import SignUp from './signUp';
import ForgotPassword from './forgotPassword';
import UpdatePassword, { UpdatePasswordKind } from './updatePassword';
//  Common ---------------------------------------------------------------------
import { Footer, Logo as LogoIcon } from '@cmp/common';
//------------------------------------------------------------------------------
// Constants -------------------------------------------------------------------
import { PhotoDisclaimer } from '@helpers/constants/legal';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Welcome extends React.Component {
  render() {
    return (
      <div className={styles.welcome}>
        <Helmet
          defaultTitle="Porsche Exclusive Gallery"
          titleTemplate="%s | Porsche Exclusive Gallery"
        />

        <div className={styles.welcome__content}>
          <div className={styles.welcome__hero}></div>

          <div className={styles.welcome__sidebar}>
            <header className={styles.sidebar__header}>
              <Link to="/">
                <LogoIcon className={styles.sidebar__logo} />
              </Link>
              <h1 className={styles.sidebar__title}>
                Porsche Exclusive Gallery
              </h1>
            </header>

            <Router>
              <SignIn path="/" />
              <SignUp path="/signup" />
              <ForgotPassword path="/forgot" />
              <UpdatePassword
                path="/retrieve"
                kind={UpdatePasswordKind.ResetPassword}
              />
              <UpdatePassword
                path="/activate"
                kind={UpdatePasswordKind.ActivateAccount}
              />
              <Redirect from="*" to="/" noThrow />
            </Router>

            <span className={styles.sidebar__disclosure}>
              {PhotoDisclaimer}
            </span>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Welcome;
