//------------------------------------------------------------------------------
// icons/logo ------------------------------------------------------------------
// Ideally we'd render the actual svg tag so we could style it properly; since
// the file is quite big and there are some parameters unsupported by React in
// there, we're simply rendering an image.
//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classnames from 'classnames';
//------------------------------------------------------------------------------
// Assets ----------------------------------------------------------------------
import Asset from './asset.svg';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Logo extends React.Component {
  render() {
    const { className } = this.props;

    const componentClasses = classnames({
      [className]: className,
    });

    return <img src={Asset} className={componentClasses} alt="Porsche Logo" />;
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Logo;
