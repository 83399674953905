//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import ReactSelect from 'react-select';
//------------------------------------------------------------------------------
// Helpers & Constants ---------------------------------------------------------
import {
  PorscheSelectTheme,
  PorschePrimaryColor,
} from '@helpers/constants/select';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Select extends React.Component {
  render() {
    const { invalid, ...props } = this.props;

    // Create styles that will only be applied if the select is marked as invalid
    const invalidStyles = invalid
      ? { border: `1px solid ${PorschePrimaryColor}` }
      : {};

    // Updating the react-select's styles using emotion (https://emotion.sh).
    // For this specific component it's easier to do it like this than managing
    // its actual css classes. More info: https://react-select.com/styles
    const selectStyles = {
      control: (provided) => ({
        ...provided,
        ...invalidStyles,
      }),
    };

    return (
      <ReactSelect
        theme={PorscheSelectTheme}
        styles={selectStyles}
        {...props}
      />
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Select;
