import moment from 'moment';

export const AllMonths = () => moment.months();

export const Years = (since = 2012) => {
  if (since < 1970)
    throw new Error(
      'Invalid since. Please select a higher lower bound (>=1970).'
    );

  const now = moment();

  let year;
  let years = [];

  while (!year || year > since) {
    year = now.year();
    years.push(now.format('Y'));

    now.subtract(1, 'year');
  }

  return years;
};
