//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import axios from 'axios';
//------------------------------------------------------------------------------
// Constants -------------------------------------------------------------------
import { StatusMessages } from '@helpers/constants/api';
//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------
import { defaultClient } from '@api/client';
//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------
import cacheClient from '@/cache/client';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { NetworkState } from '@cmp/common';
const CancelToken = axios.CancelToken;
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class GET extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: undefined,
      data: undefined,
    };

    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    const { skip } = this.props;

    if (!skip) {
      this.loadData();
    }
  }

  componentDidUpdate(prevProps) {
    const { skip: prevSkip } = prevProps;
    const { skip } = this.props;

    if (!skip && prevSkip !== skip) {
      this.loadData();
    }
  }

  componentWillUnmount() {
    // To-do (P1): Fix “Can't perform a React state update on an unmounted
    // component” error

    this.clearObserversIfNeeded();

    if (this.source) {
      this.source.cancel();
    }
  }

  objectChanged(object) {
    // Our caching client notified us that an object's been changed.

    const { cache } = this.props;
    if (!cache) return;

    // We'll let those that implement this handle the data transformation, so
    // we need to pass the data and the up-to-date object.

    const { data } = this.state;
    const updatedData = cache.updateObject(data, object);

    // As soon as they've returned the updated data, update the state.
    // This will trigger a new `render` with the new data.
    this.setState({ data: updatedData });
  }

  setupCacheIfNeeded(data) {
    // We're setting up the cache again here.

    // If this component is observing any IDs, stop.
    this.clearObserversIfNeeded();

    const { cache } = this.props;
    if (!cache) return;

    // Reset the observers array.
    this.observers = [];

    // Get the IDs based on the data
    const ids = cache.dataIdsFromData(data);

    // And for each ID, start observing it.
    ids.forEach((x) => {
      let observer = cacheClient.addObserver(x, (value) =>
        this.objectChanged(value)
      );
      this.observers.push(observer);
    });
  }

  clearObserversIfNeeded() {
    if (this.observers) {
      this.observers.forEach((observer) =>
        cacheClient.removeObserver(observer)
      );
    }
  }

  loadData(transformData) {
    let that = this;
    const { request, params, onComplete } = this.props;

    if (this.source) {
      this.source.cancel();
    }

    this.setState({ loading: true, error: null });
    this.source = CancelToken.source();

    defaultClient
      .get(request.endpoint, {
        params,
      })
      .then(({ data }) => {
        const transformedData = transformData ? transformData(data) : data;

        that.setState({ data: transformedData, loading: false, error: null });
        that.setupCacheIfNeeded(data);
        if (onComplete) onComplete(transformedData);
      })
      .catch((err) => {
        const errorState = {
          loading: false,
          error: !axios.isCancel(err) && StatusMessages.Error.Actionable,
        };

        that.setState({ ...errorState });
      });
  }

  render() {
    let that = this;
    const { loading, error, data } = this.state;
    const {
      children: render,
      stateIndicatorComponent: StateIndicatorComponent = NetworkState,
      validPlaceholder,
      didUpdateRefetch = () => undefined,
    } = this.props;
    const placeholder = validPlaceholder && validPlaceholder(data);

    const refetch = (transformData) => that.loadData(transformData);
    didUpdateRefetch(refetch);

    if (StateIndicatorComponent && (loading || error || placeholder)) {
      return (
        <StateIndicatorComponent {...this.state} placeholder={placeholder} />
      );
    } else {
      return render({ loading, error, data });
    }
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default GET;
