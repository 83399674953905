//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { FaExternalLinkAlt as ExternalLinkIcon } from 'react-icons/fa';
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { AuthenticatedPage } from '@cmp/authenticated';
import { Button, ButtonKind } from '@cmp/common';
//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------
import { regenerateAccessToken as regenerateAccessTokenRequest } from '@api/endpoints/get';
//------------------------------------------------------------------------------
// Helpers & Constants ---------------------------------------------------------
import { StatusMessages } from '@helpers/constants/api';
import { BaseUrl } from '@helpers/url';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class AccessToken extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      accessToken: null,
      error: null,
      loading: false,
    };
  }

  regenerateAccessToken() {
    const { user, onUpdate } = this.props;

    this.setState({ loading: true });

    let updatedState = {
      accessToken: null,
      error: null,
    };

    regenerateAccessTokenRequest(user)
      .then(({ data }) => {
        updatedState.accessToken = data.accessToken;
        onUpdate();
      })
      .catch((err) => {
        updatedState.error = StatusMessages.Error.Actionable;
      })
      .finally(() => {
        // To-do (P2): pass the updated token to the parent, so that the
        // SwitchAccount component, if clicked, points to the right place.

        updatedState.loading = false;
        this.setState(updatedState);
      });
  }

  render() {
    const { className } = this.props;
    const { accessToken, error, loading } = this.state;

    const componentClasses = classNames(styles.access_token, {
      [className]: className,
    });

    return (
      <AuthenticatedPage title="Access Token" className={componentClasses}>
        <p className={styles.access_token__explanation}>
          <span>
            By appending the <code>token</code> parameter in any URL, you'll be
            able to access it as this specific user.
          </span>
          <span>
            Example:{' '}
            <span className={styles.explanation__link}>
              {BaseUrl()}
              <code>?token=123</code>
            </span>
          </span>
        </p>
        <Button
          kind={ButtonKind.Interrupt}
          onClick={() => this.regenerateAccessToken()}
          loading={loading}
        >
          Generate new token
        </Button>
        {accessToken && (
          <div className={styles.access_token__regenerated}>
            <p
              className={classNames(
                styles.access_token__message,
                styles.regenerated__raw
              )}
            >
              {accessToken}
            </p>
            <a
              className={styles.regenerated__link}
              href={`${BaseUrl()}?token=${accessToken}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Open in a new tab <ExternalLinkIcon />
            </a>
          </div>
        )}
        {error && (
          <p
            className={classNames(
              styles.access_token__message,
              styles.access_token__error
            )}
          >
            {error}
          </p>
        )}
      </AuthenticatedPage>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default AccessToken;
