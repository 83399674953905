//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import GET from '@api/methods/get';
//------------------------------------------------------------------------------
// Endpoint --------------------------------------------------------------------
const Request = (id = '') => ({
  endpoint: `/posts/${id}`,
});
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
export const Post = ({ children, id, ...props }) => {
  if (!id) return children();

  return (
    <GET
      request={Request(id)}
      cache={{
        // A `GET /posts/:id` request will return a single post -- getting the
        // IDs of a query is as simple as reading directly from the data.
        dataIdsFromData: (data) => [data.id],

        // And updating the object can return the updated post.
        updateObject: (data, post) => post,
      }}
      {...props}
    >
      {children}
    </GET>
  );
};
