//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// Constants -------------------------------------------------------------------
const LegalNoticeURL = 'https://www.porsche.com/usa/legal-notice/';
const PrivacyPolicyURL = 'https://www.porsche.com/usa/privacy-policy/';
const PersonalInformationURL =
  'https://www.porsche.com/usa/privacy-policy/contact/';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Footer extends React.Component {
  render() {
    const { className } = this.props;

    const componentClasses = classNames(styles.footer, {
      [className]: className,
    });

    return (
      <footer className={componentClasses}>
        <span className={styles.footer__disclosure}>
          &#169; 2020 Porsche Cars North America, Inc.
        </span>
        <a
          href={LegalNoticeURL}
          target="_blank"
          rel="noreferrer"
          className={styles.footer__link}
        >
          Legal notice.
        </a>
        <a
          href={PrivacyPolicyURL}
          target="_blank"
          rel="noreferrer"
          className={styles.footer__link}
        >
          Privacy Policy.
        </a>
        <a
          href={PersonalInformationURL}
          target="_blank"
          rel="noreferrer"
          className={styles.footer__link}
        >
          Do Not Sell My Personal Information.
        </a>
      </footer>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Footer;
