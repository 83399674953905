//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { FaPlus as CreateIcon } from 'react-icons/fa';
import { navigate } from 'gatsby';
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { AuthenticatedPage, ImageSelector } from '@cmp/authenticated';
import { Input, Button, Select, TextArea } from '@cmp/common';
//------------------------------------------------------------------------------
// Classes ---------------------------------------------------------------------
import { postValidator } from '@/classes/postValidator';
//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------
import { Post as PostRequest } from '@api/endpoints/get';
import { createPost, createPostImage } from '@api/endpoints/post';
import { editPost } from '@api/endpoints/patch';
import { deletePostImage } from '@api/endpoints/delete';
//------------------------------------------------------------------------------
// Helpers & Constants ---------------------------------------------------------
//  External -------------------------------------------------------------------
import {
  Transmission,
  ModelLine,
  ModelYear,
  ColorTone,
} from '@helpers/constants/car';
import { constantSelectOptions } from '@helpers/select';
import { StatusMessages } from '@helpers/constants/api';
//  Local ----------------------------------------------------------------------
const PageMode = {
  CREATE: {
    title: 'Create post',
    action: {
      icon: <CreateIcon />,
      title: 'Create',
    },
    payload: {
      // If the default request (createPost|editPost) must include images.
      includeImages: true,
      method: createPost,
    },
  },
  EDIT: {
    title: 'Edit post',
    action: {
      title: 'Save',
    },
    payload: {
      // Since the payload won't include images, the code can assume they must
      // be uploaded individually
      method: editPost,
    },
  },
};
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Create extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      values: {},
      post: null,
      invalidItems: [],
      updating: {},
      loading: false,
      error: null,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.setupDataIfNeeded = this.setupDataIfNeeded.bind(this);
    this.validateIfNeeded = this.validateIfNeeded.bind(this);
    this.formValidator = postValidator();
  }

  componentDidMount() {
    this.setupDataIfNeeded();
  }

  // All form items are a `formComponent()` under the hood. It's incredibly
  // generic, receiving a label, a key and the actual input component.
  //
  // Implemented like this so that they all look the same (label size and color,
  // spacing...).
  formComponent(label, key, component) {
    // Checking if the current item (based on the `key`) is marked as invalid
    const { invalidItems } = this.state;
    const currentInvalidItem =
      invalidItems && invalidItems.find((x) => x.key === key);

    return (
      <div className={styles.form__input}>
        <label className={styles.input__label}>{label}</label>
        {/* Cloning the elements to attach the `invalid` property; useful so
            that our custom components can show a different border color,
            shadow etc */}
        {/* In this specific scenario, it does not affect the performance. More
            info here: https://stackoverflow.com/a/54929183 */}
        {React.cloneElement(component, { invalid: currentInvalidItem })}
        {currentInvalidItem && (
          <span className={styles.input__error_message}>
            {currentInvalidItem.error}
          </span>
        )}
      </div>
    );
  }

  // `inputComponent`, `selectComponent`, and `imageComponent` are helpers that
  // use the `formComponent` under the hood, in order to simplify our render
  // function.

  inputComponent(label, key, placeholder) {
    const { values } = this.state;

    const inputComponent = (
      <Input
        type="text"
        placeholder={placeholder}
        value={values[key] || ''}
        onChange={(value) => {
          // On every change, update the `values` object in our state. After
          // that, validate the form if needed (more info below).
          values[key] = value;
          this.setState({ values }, this.validateIfNeeded);
        }}
      />
    );

    return this.formComponent(label, key, inputComponent);
  }

  textAreaComponent(label, key, placeholder) {
    const { values } = this.state;

    const inputComponent = (
      <TextArea
        placeholder={placeholder}
        value={values[key] || ''}
        onChange={(value) => {
          // On every change, update the `values` object in our state. After
          // that, validate the form if needed (more info below).
          values[key] = value;
          this.setState({ values }, this.validateIfNeeded);
        }}
      />
    );

    return this.formComponent(label, key, inputComponent);
  }

  selectComponent(title, key, placeholder, options) {
    const { values } = this.state;

    // In order to have the actual select value ready when the user clicks to
    // submit the info, we're not storing the whole object returned by
    // react-select (with label and value), but only its value.
    //
    // This requires us to look up the whole thing afterwards.

    const rawValue = values[key];
    const selectedOption = options.find(({ value }) => value === rawValue);

    const selectComponent = (
      <Select
        options={options}
        placeholder={placeholder}
        value={selectedOption}
        onChange={({ value }) => {
          // Storing just the value so that our `values` object doesn't require
          // additional formatting later on.
          //
          // Also validating the form if needed as soon as the state updates.
          values[key] = value;
          this.setState({ values }, this.validateIfNeeded);
        }}
      />
    );

    return this.formComponent(title, key, selectComponent);
  }

  imageComponent() {
    let that = this;

    const pageMode = this.pageMode();
    const {
      payload: { includeImages },
    } = pageMode;

    const idealPost = this.idealPost();

    // `updating` is an attempt to create a truly generic object to represent
    // any field that is updating (even though it's only used for images now).
    const {
      values,
      updating: { images: updatingImages = [] },
    } = this.state;

    const selectorComponent = (
      <ImageSelector
        className={classNames(styles.form__input, styles.form__images)}
        // `images` consist of both File (user's computer) and Object (API)
        files={values.images}
        // If the final payload will include images, there's no limit when
        // selecting files. If we'll upload them individually, set it to 1.
        // We can change the `onSelect` so that we make multiple uploads at
        // once, but it might not be worth doing that now.
        multiple={includeImages}
        // Send ImageSelector which files are disabled
        disabled={updatingImages}
        onSelect={(files) => {
          // When the user select new files, it's crucial to know if we need to
          // upload them manually or if they'll be included in the payload later.
          // `pageMode` takes care of that.

          function updateArray() {
            const { images = [] } = values;
            const filteredFiles = files.filter((x) => images.indexOf(x) <= -1);

            values.images = [...images, ...filteredFiles];
            that.setState({ values }, that.validateIfNeeded);
          }

          // If the final payload will include the images, simply update the
          // state. If not, upload them.
          updateArray();

          if (!pageMode.payload.includeImages) {
            const file = files[0];

            createPostImage(idealPost, file)
              .then(() => undefined)
              // If something goes wrong, remove the image from the list.
              .catch((err) => {
                // This is essentially the same thing going on at `onRemove`

                const { images = [] } = values;
                const indexOf = images.indexOf(file);

                images.splice(indexOf, 1);

                values.images = images;
                this.setState({ values });
              });
          }
        }}
        onRemove={(item, isFile) => {
          // When removing an item, it's important to know if it is a File or an
          // already uploaded image. If it's a file, simply remove it from the
          // local array and it won't get uploaded when creating the post.
          // If it's an actual `postImage` (API object), make the request to
          // delete it and update the array.

          function updateArray() {
            const { images = [] } = values;
            const indexOf = images.indexOf(item);

            images.splice(indexOf, 1);

            values.images = images;
            that.setState({ values });
          }

          if (!isFile) {
            // Add the file to the updating.images array, just so that it gets
            // disabled in the ImageSelector component.
            updatingImages.push(item);

            this.setState({
              updating: { images: updatingImages },
            });

            deletePostImage(item)
              .then(updateArray)
              .finally(() => {
                // And as soon as the request's done, remove it from the list.

                const indexOf = updatingImages.indexOf(item);
                updatingImages.splice(indexOf, 1);

                this.setState({
                  updating: { images: updatingImages },
                });
              });
          } else {
            updateArray();
          }
        }}
      />
    );

    return this.formComponent('Images', 'images', selectorComponent);
  }

  validateIfNeeded() {
    // We'll only validate our inputs `onChange` if there is anything with an
    // error already (so that the user knows exactly when the form is ready to
    // be sent). There's no validation when the user is typing on the form
    // initially so that they don't see a bunch of error messages right away.

    const { values, invalidItems: currentInvalidItems } = this.state;
    if (!currentInvalidItems || currentInvalidItems.length <= 0) return;

    const { invalidItems } = this.formValidator.validateAll(values);
    this.setState({ invalidItems });
  }

  onSubmit(e) {
    e.preventDefault();

    // When clicking to submit (or pressing `enter` on the form) the first thing
    // we do is make sure all fields are valid.
    const { values } = this.state;
    const { isValid, invalidItems } = this.formValidator.validateAll(values);
    const pageMode = this.pageMode();

    // If they aren't, store the invalid items the FormValidator class returns.
    if (!isValid) {
      this.setState({ invalidItems });
      return;
    }

    // If they're all good, simply make a POST request with the stored data.
    // Our API requires two separate instances of images and the post.
    const { images, ...post } = values;

    // Show the loading indicator in the “create” button (also disabling it so
    // that) users can't press it twice.
    this.setState({ loading: true });

    const payload = { post };
    if (pageMode.payload.includeImages) payload.images = images;

    const method = pageMode.payload.method;

    method(payload)
      // If the post was created successfully, redirect the user to the previous
      // page. Admins will most likely go back to the homepage, regular users
      // will go back to the “My Posts” page.
      // Gatsby will fallback to the homepage if necessary.
      .then((res) => navigate('..'))
      .catch((err) => {
        const { response: { data } = {} } = err || {};

        // Or store the error message and remove the loading indicator
        this.setState({
          loading: false,
          error: data.message || StatusMessages.Error.Generic,
        });
      });
  }

  pageMode() {
    const { id } = this.props;
    return id ? PageMode.EDIT : PageMode.CREATE;
  }

  idealPost() {
    // The “ideal post” will be either what was loaded from the API (technically
    // the most up to date data), or what was passed to this screen in the
    // locations' state.

    const {
      location: { state = {} },
    } = this.props;
    const { post } = this.state;

    return post || (state && state.post);
  }

  setupDataIfNeeded() {
    const post = this.idealPost();
    if (!post) return this.setState({ values: {} });

    // Unfortunately we need to send only the actual fields to the API later on,
    // so we must filter out only the fields we need here (instead of using an
    // object spread and getting everything).

    const {
      id,
      title,
      description,
      modelLine,
      modelType,
      modelYear,
      exteriorColorTone,
      exteriorColorDescription,
      exteriorColorCode,
      interiorColor,
      transmission,
      commissionNumber,
      vin,
      postImages: images,
    } = post;

    this.setState({
      values: {
        id,
        title,
        description,
        modelLine,
        modelType,
        exteriorColorTone,
        exteriorColorDescription,
        exteriorColorCode,
        interiorColor,
        transmission,
        commissionNumber,
        vin,
        // The only value that doesn't map automatically from the Post object to
        // our internal values is modelYear. The API treats it as a number, and
        // we treat it as a string.
        modelYear: modelYear.toString(),
        images,
      },
    });
  }

  render() {
    const { className, id } = this.props;
    const { error, loading } = this.state;

    const pageMode = this.pageMode();
    const idealPost = this.idealPost();

    const componentClasses = classNames(styles.create, {
      [className]: className,
    });

    return (
      <AuthenticatedPage title={pageMode.title} className={componentClasses}>
        <PostRequest
          id={id}
          skip={!id}
          onComplete={(post) => this.setState({ post }, this.setupDataIfNeeded)}
          // Show the state indicator component (loading indicators, error
          // messages...) only if there's no cached post. Otherwise, treat it as
          // an undefined variable.
          stateIndicatorComponent={idealPost ? null : undefined}
        >
          {() => (
            <form className={styles.create__form} onSubmit={this.onSubmit}>
              <div className={styles.form__content}>
                {/* We're separating the form into two columns here. This can also
                    be managed using CSS (grids or flexbox). My main concern by
                    doing that is that in bigger screens the user will only see
                    one row of items (unless we set an explict max-width?). */}
                {/* For now this should work just fine. */}
                <div className={styles.form__column}>
                  {this.inputComponent(
                    'Title',
                    'title',
                    'Acid Green 911 Turbo S Cabriolet at PCNA'
                  )}
                  {this.textAreaComponent(
                    'Description',
                    'description',
                    'Provide a very short teaser about the car, list exclusive options by name and code, and any CXX.'
                  )}
                  {this.selectComponent(
                    'Model Line',
                    'modelLine',
                    'Select the model line...',
                    constantSelectOptions(ModelLine)
                  )}
                  {this.inputComponent('Model Type', 'modelType', 'GT3')}
                  {this.selectComponent(
                    'Model Year',
                    'modelYear',
                    'Select the model year...',
                    ModelYear()
                  )}
                  {this.selectComponent(
                    'Color Tone',
                    'exteriorColorTone',
                    'Select the exterior color...',
                    constantSelectOptions(ColorTone)
                  )}
                  {this.inputComponent(
                    'Exterior Color Code',
                    'exteriorColorCode',
                    'Acid Green'
                  )}
                </div>
                <div className={styles.form__column}>
                  {this.inputComponent(
                    'Exterior Color Description',
                    'exteriorColorDescription',
                    'Acid Green'
                  )}
                  {this.inputComponent(
                    'Interior Color',
                    'interiorColor',
                    'Black'
                  )}
                  {this.selectComponent(
                    'Transmission',
                    'transmission',
                    'Select the transmission type...',
                    constantSelectOptions(Transmission)
                  )}
                  {this.inputComponent(
                    'Commission Number',
                    'commissionNumber',
                    'B12345'
                  )}
                  {this.inputComponent('VIN', 'vin', 'WPOAB2A85JS123456')}
                  {this.imageComponent()}
                </div>
              </div>
              <span className={styles.create__disclaimer}>
                Note: type in 'N/A' if the information is missing.
              </span>
              <Button
                type="submit"
                className={styles.form__submit}
                loading={loading}
              >
                {pageMode.action.icon}
                <span>{pageMode.action.title}</span>
              </Button>
              {error && <span className={styles.form__error}>{error}</span>}
            </form>
          )}
        </PostRequest>
      </AuthenticatedPage>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Create;
