//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import moment from 'moment';
import prettyBytes from 'pretty-bytes';
//------------------------------------------------------------------------------
// Constants -------------------------------------------------------------------
//  External -------------------------------------------------------------------
import { ReadableUserRoles } from '@helpers/constants/user';
//  Internal -------------------------------------------------------------------
const RemoteDateFormat = 'Y-MM-DD HH:mm:ss A';
const DateFormat = 'MMM Do, h:mm a';
//------------------------------------------------------------------------------
// User ------------------------------------------------------------------------
export const userFormatter = (user) => ({
  fullName: () => {
    if (!user) return '';

    const validFirstName = user.firstName && user.firstName.length > 0;
    const validLastName = user.lastName && user.lastName.length > 0;

    if (!validFirstName && !validLastName) return '';
    if (validFirstName && !validLastName) return user.firstName;
    if (validLastName && !validFirstName) return user.lastName;

    return user.firstName + ' ' + user.lastName;
  },
  roles: () => {
    const { roles } = user || {};
    if (!roles || roles.lenght <= 0) return false;

    const mappedRoles = roles.map((x) => ReadableUserRoles[x]);
    return mappedRoles.filter((x) => x && x.length > 0);
  },
  hasRole: (role) => {
    const { roles } = user || {};
    if (!roles || roles.lenght <= 0) return false;

    return roles.indexOf(role) > -1;
  },
});
//------------------------------------------------------------------------------
// Noun ------------------------------------------------------------------------
export const nounFormatter = {
  idealForm: (singularaNoun, pluralNoun, amount, withNumber) => {
    if (amount !== 1)
      return withNumber ? `${amount} ${pluralNoun}` : pluralNoun;
    return withNumber ? `${amount} ${singularaNoun}` : singularaNoun;
  },
};
//------------------------------------------------------------------------------
// Post ------------------------------------------------------------------------
const PostDescriptionCharacterLimit = 120;

export const postFormatter = ({ createdAt, description, modelYear }) => ({
  createdOn: () => {
    // We're storing the date as UTC in the database. When parsing it, let
    // moment know that it should use that.
    let date = moment.utc(createdAt, RemoteDateFormat);

    // Then, convert it to the user's local timezone.
    date.local();

    // More info here:
    // https://momentjs.com/docs/#/parsing/utc/
    // https://momentjs.com/docs/#/manipulating/local/

    return {
      formatted: date.format(DateFormat),
      fromNow: date.fromNow(),
    };
  },
  trimmedDescription:
    description && description.length > PostDescriptionCharacterLimit
      ? `${description.substring(0, PostDescriptionCharacterLimit)}...`
      : description,
  year:
    modelYear &&
    `MY${String(modelYear).substring(String(modelYear).length - 2)}`,
});
//------------------------------------------------------------------------------
// File ------------------------------------------------------------------------
export const fileFormatter = (file) => ({
  formattedInfo: `${prettyBytes(file.size)} | ${moment(
    file.lastModified
  ).format(DateFormat)}`,
});
//------------------------------------------------------------------------------
// Validation ------------------------------------------------------------------
export const validationFormatter = ({ invalidItems }) => ({
  list: () => {
    if (!invalidItems) return '';

    return (
      <ul>
        {invalidItems.map((x) => (
          <li key={x.key}>{x.error}</li>
        ))}
      </ul>
    );
  },
});
